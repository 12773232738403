import * as React from "react";

import { Button } from "~/components/button";

import { ButtonContainer } from "./styles";
import { genericMessages } from "~/constants/intl";
import { isWeb } from "~/utils/platform";

interface Props {
  onSave: () => void;
}

export const SaveButton = ({ onSave }: Props) => {
  if (!isWeb) {
    return null;
  }
  return (
    <ButtonContainer>
      <Button text={genericMessages.save} small onPress={onSave} />
    </ButtonContainer>
  );
};
