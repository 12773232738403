import { Environment } from "~/state/user/types";

export const getBibleCollection = (suffix: Environment) => `bibles_${suffix}`;

export const getGroupsCollection = (suffix: Environment) => `groups_${suffix}`;

export const getMessagesCollection = (suffix: Environment) =>
  `messages_${suffix}`;

export const getGroupsImageFolder = (suffix: Environment) => `groups_${suffix}`;

export const getInviteCodeEndpoint = (suffix: Environment) =>
  `groups-getCode-${suffix}`;

export const getFaithlifeNotesCollection = (suffix: Environment) =>
  `faithlife_notes_${suffix}`;

export const getSessionNotesCollection = (suffix: Environment) =>
  `session_notes_${suffix}`;

export const getWalkthroughCollection = (suffix: Environment) =>
  `walkthrough_${suffix}`;

export const getSubscriptionsCollection = (suffix: Environment) =>
  `subscriptions_${suffix}`;

export const getUsersCollection = () => "user_profiles";

export const getProfileImageFolder = (suffix: Environment) =>
  `profile_${suffix}`;

export const getBibleNotesCollection = (suffix: Environment) =>
  `bible_notes_${suffix}`;

export const getBibleHighlightsCollection = (suffix: Environment) =>
  `bible_highlights_${suffix}`;

export const getPersonalNotesCollection = (suffix: Environment) =>
  `personal_notes_${suffix}`;

export const getCmsContentCollection = (suffix: Environment) =>
  `cms_content_${suffix}`;

export const getFiltersCollection = (suffix: Environment) =>
  `filters_${suffix}`;

export const getUserDeleteFunction = (suffix: Environment) =>
  `user-delete-${suffix}`;

export const getUpdateUserFunction = () => "user-update";

export const getPrayerRequestsCollection = (suffix: Environment) =>
  `prayer_requests_${suffix}`;

export const getSessionProgressCollection = (suffix: Environment) =>
  `session_progress_${suffix}`;

export const getPlanProgressCollection = (suffix: Environment) =>
  `plan_progress_${suffix}`;

export const getAppFeedbackCollection = (suffix: Environment) =>
  `app_feedback_${suffix}`;

export const getInAppNotificationsCollection = (suffix: Environment) =>
  `in_app_notifications_${suffix}`;

export const getAnalyticsCollection = (suffix: Environment) =>
  `analytics_${suffix}`;
