import * as Crypto from "expo-crypto";
import * as AppleAuthentication from "expo-apple-authentication";
import { Platform } from "react-native";
import { eventChannel, SagaIterator } from "redux-saga";
import { jwtDecode } from "jwt-decode";
import {
  takeEvery,
  takeLatest,
  put,
  call,
  select,
  delay,
  race,
  take,
} from "redux-saga/effects";
import {
  OAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCredential,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset,
  checkActionCode,
  applyActionCode,
} from "firebase/auth";
import { doc, setDoc, getDoc, arrayUnion, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { auth, database, storage } from "<config>/firebase";
import { asyncLogEvent, events } from "~/utils/analytics";
import { getBlob } from "~/utils/file";
import { handleError } from "~/utils/logger";
import { addTrace, setUserId, setAttributes } from "~/utils/crashlytics";
import { setUserId as setUserIdAnalytics } from "~/utils/analytics";
import { appleAuthHelpers } from "~/utils/apple-sign-in-auth";
import { getPendingInviteCode } from "~/state/groups/selectors";
import { joinGroup } from "~/state/groups/actions";
import { resetInviteCode } from "~/state/groups/slice";
import { removeTokenSaga } from "~/state/push-notifications";
import { importFaithlifeNotes } from "~/state/session-notes/sagas";
import { deleteAllDownloads } from "~/state/flamelink/actions";
import {
  resetTutorialState,
  setGroupsSeen,
  setInitialGroupsSeen,
  setShouldShowTutorial,
} from "~/state/tutorial/slice";
import { getWalkthrough } from "~/state/tutorial/actions";
import {
  AUTH_CLIENT_ID,
  AUTH_REDIRECT_URI,
  FIREBASE_WEB_APP_URL,
} from "~/constants";
import {
  getProfileImageFolder,
  getUsersCollection,
} from "~/constants/collections";

import {
  login,
  signup,
  saveProfile,
  loginWithApple,
  loginWithGoogle,
  verifyEmail,
  sendEmailVerification as sendEmailVerificationAction,
  sendPasswordReset,
  updatePassword,
  preLogout,
  editProfile,
  changePassword,
  deleteAccount,
  startEmailVerificationPolling,
  stopEmailVerificationPolling,
  triggerResetAPICache,
  setIsUserReady,
  changeEmail,
} from "./actions";
import {
  logout,
  setProfile,
  setUserData,
  setEmailVerified,
  initialState,
  setPartialProfile,
  setUserEmail,
} from "./slice";
import {
  getEnvironment,
  getHasProfile,
  getUserEmail,
  getUserId,
} from "./selectors";
import {
  User,
  LoginSagaPayload,
  SignupSagaPayload,
  VerifyEmailAction,
  SendEmailVerificationAction,
  SaveProfileSagaPayload,
  ForgottenPasswordAction,
  UpdatePasswordAction,
  GoogleLoginAction,
  EditProfileAction,
  Profile,
  ChangePasswordAction,
  DeleteAccountAction,
  OptInStatus,
  ChangeEmailAction,
  SetTutorialSeenAction,
} from "./types";
import { contentProgressApi, setContentProgress } from "../content-progress";
import { sessionNotesApi } from "../session-notes";
import { executeChangePassword } from "./side-effects";
import { deleteUser, updateEmail } from "./side-effects";
import { resetFiltersFetchTime } from "../ui";
import { resetFeaturedFetchTime, resetFlamelinkData } from "../flamelink";
import { isWeb } from "~/utils/platform";
import { getParam } from "~/utils/params";

const createAuthStateChannel = () => {
  return eventChannel((emit) => {
    return onAuthStateChanged(
      auth,
      (user) => {
        emit({
          user,
        });
      },
      (error) => emit({ error })
    );
  });
};

const addToGroupSaga = function* (userId: string): SagaIterator {
  const inviteCode = yield select(getPendingInviteCode);
  if (!inviteCode) {
    return;
  }
  yield put(joinGroup({ inviteCode, userId }));
  yield put(resetInviteCode());
};

export const setUserSaga = function* ({ user }: { user: User }): SagaIterator {
  if (!user) {
    yield put(setUserData(initialState));
    return;
  }
  try {
    const userId = user.uid;
    if (!userId) {
      return;
    }
    yield put(setIsUserReady({ userId }));

    const usersCollection = getUsersCollection();
    // Potentially add user to the group
    yield call(addToGroupSaga, userId);

    if (auth.currentUser) {
      const idTokenResult = yield call([
        auth.currentUser,
        auth.currentUser.getIdTokenResult,
      ]);
      user.isAdmin = !!idTokenResult?.claims?.admin;
    }

    yield call(addTrace, "User logged in");
    yield call(setUserId, userId);
    yield call(setAttributes, { email: user.email });

    yield call(setUserIdAnalytics, userId);

    const docRef = doc(database, usersCollection, userId);
    // @ts-ignore
    const profile = yield call(getDoc, docRef);
    if (profile.exists()) {
      const profileData = profile.data();
      yield put(setUserData({ user, profile: profileData }));
      if (Array.isArray(profileData?.walkthroughSeen)) {
        yield put(setInitialGroupsSeen(profileData.walkthroughSeen));
      }
    } else {
      yield put(setUserData({ user }));
    }
  } catch (e) {
    yield put(setUserData(initialState));
    yield call(handleError, e);
  }
};

export const authSaga = function* () {
  try {
    const authStateChannel = createAuthStateChannel();

    yield takeEvery(authStateChannel, setUserSaga);
  } catch (e) {
    yield put(setUserData(initialState));
    yield call(handleError, e);
  }
};

export function* handleLogin({
  payload: { email, password, onSuccess, onError },
}: LoginSagaPayload): SagaIterator {
  try {
    yield call(signInWithEmailAndPassword, auth, email, password);
    yield call(onSuccess);
  } catch (e: any) {
    yield call(onError, e?.message);
    yield call(handleError, e);
  }
}

export const loginSaga = function* () {
  yield takeLatest(login.type, handleLogin);
};

export function* handleVerifyEmail({
  payload: { code, onSuccess, onError },
}: VerifyEmailAction): SagaIterator {
  try {
    yield call(checkActionCode, auth, code);
    yield call(applyActionCode, auth, code);
    yield put(setEmailVerified());
    yield call(onSuccess);
  } catch (e: any) {
    yield call(onError, e?.message);
    yield call(handleError, e);
  }
}

export const verifyEmailSaga = function* () {
  yield takeLatest(verifyEmail.type, handleVerifyEmail);
};

const handleSendEmailVerification = function* ({
  payload: { onSuccess, onError },
}: SendEmailVerificationAction): SagaIterator {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User is not logged in");
    }
    sendEmailVerification(currentUser, {
      url: FIREBASE_WEB_APP_URL,
    })
      .then(onSuccess)
      .catch((e) => {
        onError(e?.message);
        handleError(e);
      });
  } catch (error: any) {
    yield call(onError, error?.message);
    yield call(handleError, error);
  }
};

export const sendEmailVerificationSaga = function* () {
  yield takeLatest(
    sendEmailVerificationAction.type,
    handleSendEmailVerification
  );
};

export function* resetAPICache() {
  try {
    yield put(contentProgressApi.util.resetApiState());
    // @TODO: check how we are going to handle this as we won't be using flamelink api anymore
    // yield put(flamelinkApi.util.resetApiState());
    yield put(sessionNotesApi.util.resetApiState());
    yield put(setContentProgress([]));
    yield put(resetFiltersFetchTime());
    yield put(resetFeaturedFetchTime());
    yield put(resetTutorialState());
    if (isWeb) {
      yield put(resetFlamelinkData());
    }
  } catch (error) {
    yield call(handleError, error);
  }
}

export const resetAPICacheSaga = function* () {
  yield takeLatest(triggerResetAPICache.type, resetAPICache);
};

// Handle all the actions that require userId before the proper logout
export function* handlePreLogout(): SagaIterator {
  try {
    // Remove the push notifications token
    yield call(removeTokenSaga);

    // An actual logout
    yield put(logout());
  } catch (e) {
    yield call(handleError, e);
  }
}

export const preLogoutSaga = function* () {
  yield takeLatest(preLogout.type, handlePreLogout);
};

export function* handleLogout(): SagaIterator {
  try {
    yield call(signOut, auth);
    yield call(resetAPICache);
  } catch (e) {
    yield call(handleError, e);
  }
}

export const logoutSaga = function* () {
  yield takeLatest(logout.type, handleLogout);
};

export function* handleSignup({
  payload: { email: payloadEmail, password, onSuccess, onError },
}: SignupSagaPayload): SagaIterator {
  const email = payloadEmail.trim();
  try {
    const result = yield call(
      createUserWithEmailAndPassword,
      auth,
      email,
      password
    );

    sendEmailVerification(result.user, {
      url: FIREBASE_WEB_APP_URL,
    });

    yield call(asyncLogEvent, events.USER_LOGIN_STANDARD);

    // Import the Faithlife notes if exist
    yield call(importFaithlifeNotes, { email, userId: result.user?.uid });

    yield call(onSuccess);
  } catch (e: any) {
    yield call(onError, e?.message);
    yield call(handleError, e);
  }
}

export const signupSaga = function* () {
  yield takeLatest(signup.type, handleSignup);
};

export function* handleUploadImage(uri: string, path: string): SagaIterator {
  try {
    const uid = yield select(getUserId);

    const storageRef = ref(storage, path);

    const blob: Blob = yield call(getBlob, uri);

    // There might be some issue with uploading the files with Firebase JS SDK and Expo that we
    // should keep an eye on: https://github.com/firebase/firebase-js-sdk/issues/5848#issuecomment-1279292900
    // @ts-ignore
    yield call(uploadBytesResumable, storageRef, blob);
    // @ts-ignore
    const profileUrl = yield call(getDownloadURL, storageRef);

    return profileUrl;
  } catch (e: any) {
    yield call(handleError, e);
    return "";
  }
}

export function* handleSaveProfile({
  payload: { onSuccess, onError, ...data },
}: SaveProfileSagaPayload): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const usersCollection = getUsersCollection();
    const profileImageFolder = getProfileImageFolder(env);

    const referral = yield call(getParam, "referral");
    const uid = yield select(getUserId);
    const path = `${profileImageFolder}/${uid}.jpg`;
    let profileUrl = "";

    if (data.image) {
      profileUrl = yield call(handleUploadImage, data.image, path);
    }

    const webData = isWeb ? { referral } : {};

    const emailAddress = yield select(getUserEmail);

    const docRef = doc(database, usersCollection, uid);

    const userData = {
      ...data,
      ...webData,
      emailAddress,
      image: profileUrl,
      emailOptIn: OptInStatus.subscribed,
    };

    // @ts-ignore
    yield call(setDoc, docRef, userData, { merge: true });
    yield put(setProfile(userData as Profile));
    yield put(getWalkthrough());
    yield put(setShouldShowTutorial());
    yield call(onSuccess);
  } catch (e: any) {
    yield call(onError, e?.message);
    yield call(handleError, e);
  }
}

export const saveProfileSaga = function* () {
  yield takeLatest(saveProfile.type, handleSaveProfile);
};

export function* handleEditProfile({
  payload: { onSuccess, onError, ...data },
}: EditProfileAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const usersCollection = getUsersCollection();
    const profileImageFolder = getProfileImageFolder(env);

    const uid = yield select(getUserId);
    const path = `${profileImageFolder}/${uid}.jpg`;
    let profileUrl = undefined;

    if (data.image) {
      profileUrl = yield call(handleUploadImage, data.image, path);
    }

    const docRef = doc(database, usersCollection, uid);

    const userData = {
      ...data,
      ...(profileUrl ? { image: profileUrl } : {}),
    };

    // @ts-ignore
    yield call(setDoc, docRef, userData, { merge: true });
    // we set a partial profile because we don't want to override the whole profile upon editing
    yield put(setPartialProfile(userData as Profile));
    if (typeof onSuccess === "function") {
      yield call(onSuccess);
    }
  } catch (e: any) {
    if (typeof onError === "function") {
      yield call(onError, e?.message);
    }
    yield call(handleError, e);
  }
}

export const editProfileSaga = function* () {
  yield takeLatest(editProfile.type, handleEditProfile);
};

function* getAppleCredential(state: string, nonce: string): SagaIterator {
  if (Platform.OS === "web") {
    const response = yield call(appleAuthHelpers.signIn, {
      authOptions: {
        clientId: AUTH_CLIENT_ID,
        scope: "email name",
        redirectURI: AUTH_REDIRECT_URI,
        nonce,
        state,
        usePopup: true,
      },
    });
    const authorization = response?.authorization || {};

    return {
      fullName: {
        givenName: authorization.givenName,
        familyName: authorization.familyName,
      },
      identityToken: authorization.id_token,
    };
  } else {
    const appleCredential = yield call(AppleAuthentication.signInAsync, {
      requestedScopes: [
        AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
        AppleAuthentication.AppleAuthenticationScope.EMAIL,
      ],
      state,
      nonce,
    });

    return appleCredential;
  }
}

export function* handleLoginWithApple(): SagaIterator {
  try {
    const csrf = Math.random().toString(36).substring(2, 15);
    const nonce = Math.random().toString(36).substring(2, 10);
    const hashedNonce = yield call(
      Crypto.digestStringAsync,
      Crypto.CryptoDigestAlgorithm.SHA256,
      nonce
    );

    const appleCredential = yield call(getAppleCredential, csrf, hashedNonce);

    const { identityToken, fullName } = appleCredential;

    if (identityToken) {
      const provider = new OAuthProvider("apple.com");
      const credential = provider.credential({
        idToken: identityToken,
        rawNonce: nonce,
      });
      const { user } = yield call(signInWithCredential, auth, credential);
      const { givenName, familyName } = fullName || {};
      const displayName =
        givenName && familyName ? `${givenName} ${familyName}` : "";
      const nameProps = displayName ? { displayName } : {};

      yield call(asyncLogEvent, events.USER_LOGIN_APPLE);

      yield call(setUserSaga, { user: { ...user, ...nameProps } });
    }
  } catch (e: any) {
    console.warn(e?.message);
    yield call(handleError, e);
  }
}

export const loginWithAppleSaga = function* () {
  yield takeLatest(loginWithApple.type, handleLoginWithApple);
};

function* getUsernameFromToken(token: string) {
  try {
    const { displayName } = yield call(jwtDecode, token);
    return displayName;
  } catch (e) {
    handleError(e);
  }
}

export function* handleLoginWithGoogle({
  payload: { idToken },
}: GoogleLoginAction): SagaIterator {
  try {
    const credential = GoogleAuthProvider.credential(idToken);
    if (!credential.idToken) {
      return;
    }
    const displayName = getUsernameFromToken(credential.idToken);
    const nameProps = displayName ? { displayName } : {};

    const { user } = yield call(signInWithCredential, auth, credential);
    if (displayName) {
      yield call(setUserSaga, { user: { ...user, ...nameProps } });
    }

    yield call(asyncLogEvent, events.USER_LOGIN_GOOGLE);
  } catch (e: any) {
    yield call(handleError, e);
  }
}

export const loginWithGoogleSaga = function* () {
  yield takeLatest(loginWithGoogle.type, handleLoginWithGoogle);
};

export function* handleForgottenPassword({
  payload: { email, onSuccess, onError },
}: ForgottenPasswordAction): SagaIterator {
  try {
    sendPasswordResetEmail(auth, email, {
      url: FIREBASE_WEB_APP_URL,
    });

    yield call(onSuccess);
  } catch (e: any) {
    yield call(onError, e?.message);
    yield call(handleError, e);
  }
}

export function* handleUpdatePassword({
  payload: { password, code, onSuccess, onError },
}: UpdatePasswordAction): SagaIterator {
  try {
    yield call(confirmPasswordReset, auth, code, password);

    yield call(onSuccess);
  } catch (e: any) {
    yield call(onError, e?.message);
    yield call(handleError, e);
  }
}

export const forgottenPasswordSaga = function* () {
  yield takeLatest(sendPasswordReset.type, handleForgottenPassword);
};

export const updatePasswordSaga = function* () {
  yield takeLatest(updatePassword.type, handleUpdatePassword);
};

export function* handleChangePassword({
  payload,
}: ChangePasswordAction): SagaIterator {
  try {
    yield call(executeChangePassword, payload);
  } catch (error) {
    if (error instanceof Error) {
      yield call(handleError, error);
      yield call(payload.onError, error?.message);
    }
  }
}

export const changePasswordSaga = function* () {
  yield takeLatest(changePassword.type, handleChangePassword);
};

function* handleDeleteAccount({ payload }: DeleteAccountAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const result: Awaited<ReturnType<typeof deleteUser>> = yield call(
      deleteUser,
      env,
      payload.deleteOnlyData
    );

    // Delete user downloaded files
    yield put(deleteAllDownloads());

    if (result.data?.uid) {
      yield call(payload.onSuccess);
      yield put(setContentProgress([]));
    } else {
      yield call(handleError, result.data?.error);
      yield call(payload.onError, result.data?.error);
    }
  } catch (error) {
    yield call(handleError, error);

    if (error instanceof Error) {
      yield call(payload.onError, error?.message);
    }
  }
}

export const deleteAccountSaga = function* () {
  yield takeLatest(deleteAccount.type, handleDeleteAccount);
};

function* pollEmailVerification() {
  try {
    while (true) {
      if (auth?.currentUser && !auth.currentUser.emailVerified) {
        yield call([auth.currentUser, auth.currentUser.reload]);

        if (!!auth.currentUser.emailVerified) {
          // @ts-ignore
          yield call(setUserSaga, { user: auth.currentUser });
          break;
        }

        yield delay(3000);
      } else {
        break;
      }
    }
  } catch (error) {
    handleError(error);
  } finally {
    yield put(stopEmailVerificationPolling());
  }
}

export const emailVerificationPollingSaga = function* () {
  while (true) {
    yield take(startEmailVerificationPolling.type);
    yield race([
      call(pollEmailVerification),
      take(stopEmailVerificationPolling.type),
    ]);
  }
};

export function* handleChangeEmail({
  payload: { email, onSuccess, onError },
}: ChangeEmailAction): SagaIterator {
  try {
    const userId = yield select(getUserId);

    const result: Awaited<ReturnType<typeof updateEmail>> = yield call(
      updateEmail,
      email,
      userId
    );

    if (result.data?.email) {
      yield put(setUserEmail(result.data?.email));
      yield call(onSuccess);
    } else if (result?.data?.error) {
      throw new Error(result?.data?.error);
    } else {
      yield call(onError);
    }
  } catch (e: any) {
    yield call(onError, e?.message);
    yield call(handleError, e);
  }
}

export const changeEmailSaga = function* () {
  yield takeLatest(changeEmail.type, handleChangeEmail);
};

export function* setTutorialGroupSeen({
  payload,
}: SetTutorialSeenAction): SagaIterator {
  try {
    if (!Array.isArray(payload)) {
      return;
    }
    const usersCollection = getUsersCollection();
    const uid = yield select(getUserId);

    const data = {
      walkthroughSeen: arrayUnion(...payload),
    };

    // @ts-ignore
    yield call(updateDoc, doc(database, usersCollection, uid), data);
  } catch (e: any) {
    yield call(handleError, e);
  }
}

export const setTutorialGroupsSeenSaga = function* () {
  yield takeLatest(setGroupsSeen.type, setTutorialGroupSeen);
};
