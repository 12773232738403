import { SagaIterator } from "redux-saga";
import { takeLatest, call, select, put } from "redux-saga/effects";

import {
  query,
  collection,
  getDocs,
  DocumentSnapshot,
} from "firebase/firestore";

import { database } from "<config>/firebase";

import { getInAppNotificationsCollection } from "~/constants/collections";
import { handleError } from "~/utils/logger";
import { setIsUserReady } from "~/state/user/actions";
import { getEnvironment } from "~/state/user/selectors";

import { NotificationData } from "./types";
import { getNotificationsBlacklist } from "./selectors";
import { setNotification } from "./slice";

export const getInAppNotificationsFn = function* (): SagaIterator {
  try {
    const blacklist = yield select(getNotificationsBlacklist);
    const env = yield select(getEnvironment);
    const collectionName = getInAppNotificationsCollection(env);

    const snapshots = yield call(
      // @ts-ignore
      getDocs,
      collection(database, collectionName)
    );

    const data = snapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as NotificationData[];

    const now = new Date().getTime();

    const notification = data.find((item) => {
      const schedule = item?.schedule ? item?.schedule?.toDate().getTime() : 0;
      const expiration = item?.expiration
        ? item?.expiration?.toDate().getTime()
        : 0;

      return (
        schedule < now && expiration > now && !blacklist.includes(item?.id)
      );
    });

    if (notification && notification.id) {
      yield put(setNotification(notification));
    }
  } catch (e) {
    yield call(handleError, e);
  }
};

export const getInAppNotificationsSaga = function* () {
  yield takeLatest(setIsUserReady.type, getInAppNotificationsFn);
};
