export enum Screens {
  AmountSelection = "amount",
  PaymentDetails = "details",
  Success = "success",
  Intro = "account",
  History = "history",
  Manage = "manage",
  PaymentMethod = "payment-method",
  PaymentMethodAdd = "payment-method-add",
}
