import {
  DocumentData,
  DocumentReference,
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { database } from "<config>/firebase";
import {
  AddSessionProgressParams,
  GetPlanProgressesData,
  ProgressState,
  SessionProgress,
  UpdateSessionProgressParams,
} from "./types";
import { getHasAnyProgress, getNewSessionProgressToStore } from "./utils";
import {
  getPlanProgressCollection,
  getSessionProgressCollection,
  getAnalyticsCollection,
} from "~/constants/collections";
import { Environment } from "~/state/user/types";
import { asyncLogEvent, events } from "~/utils/analytics";
import { getSessionsByPlanId } from "../flamelink/selectors";
import { RootState } from "../store";

async function addNewProgress(
  userId: string,
  progressParams: AddSessionProgressParams,
  result: DocumentReference<DocumentData>,
  progressCollection: string
) {
  const newPlanProgressData: GetPlanProgressesData = {
    userId,
    planId: progressParams.planId,
    sessions: [result],
  };

  const newPlanProgressResult = await addDoc(
    collection(database, progressCollection),
    newPlanProgressData
  );

  return newPlanProgressResult.id;
}

const updatePlanProgressData = async (
  planId: string,
  env: Environment,
  key: string
) => {
  const analyticsCollection = getAnalyticsCollection(env);
  await updateDoc(
    doc(database, analyticsCollection, "plan-progress", "data", planId),
    {
      [key]: increment(1),
    }
  );
};

export async function addSessionProgress(
  addSessionProgressParams: AddSessionProgressParams,
  userId: string,
  env: Environment
): Promise<{ data: string }> {
  const sessionProgressToStore = getNewSessionProgressToStore({
    userId,
    ...addSessionProgressParams,
  });

  const sessionProgressCollection = getSessionProgressCollection(env);
  const planProgressCollection = getPlanProgressCollection(env);

  const result = await addDoc(
    collection(database, sessionProgressCollection),
    sessionProgressToStore
  );

  const planProgressId = addSessionProgressParams?.planProgressId;

  if (planProgressId) {
    const planProgressDocRef = doc(
      database,
      planProgressCollection,
      planProgressId
    );

    const planProgressDoc = await getDoc(planProgressDocRef);

    if (planProgressDoc.exists()) {
      const planProgressData = planProgressDoc.data() as GetPlanProgressesData;

      if (addSessionProgressParams.planId !== planProgressData.planId) {
        // create a new plan progress or edit an existing one

        const data = await addNewProgress(
          userId,
          addSessionProgressParams,
          result,
          planProgressCollection
        );

        return { data };
      }

      const newPlanProgressData = {
        ...planProgressData,
        sessions: [...planProgressData.sessions, result],
      };

      await updateDoc(planProgressDocRef, newPlanProgressData);

      return { data: planProgressId as string };
    } else {
      const data = await addNewProgress(
        userId,
        addSessionProgressParams,
        result,
        planProgressCollection
      );

      return { data };
    }
  } else {
    const planId = addSessionProgressParams.planId;
    const newPlanProgressData: GetPlanProgressesData = {
      userId,
      planId,
      sessions: [result],
    };

    const newPlanProgressResult = await addDoc(
      collection(database, planProgressCollection),
      newPlanProgressData
    );

    await updatePlanProgressData(planId, env, "numUsersStarted");

    return { data: newPlanProgressResult.id as string };
  }
}

export async function updateSessionProgress(
  updateSessionProgressParams: UpdateSessionProgressParams,
  env: Environment,
  getState?: () => RootState
) {
  const {
    sessionProgressId,
    dayKey,
    shouldMarkDayAsComplete,
    shouldOmitFirstDayCheck,
    shouldOmitLastDayCheck,
    omitMarkingDayInProgress = false,
    initialState = ProgressState.InProgress,
    allowOverwriteSessionState = true,
  } = updateSessionProgressParams;
  const sessionProgressCollection = getSessionProgressCollection(env);

  const querySnapshot = await getDoc(
    doc(database, sessionProgressCollection, sessionProgressId)
  );

  const data = querySnapshot.data() as SessionProgress;

  const newDaysProgressToStore = data?.days.map((day) => {
    if (day.key === dayKey) {
      const newDayState = shouldMarkDayAsComplete
        ? ProgressState.Completed
        : !omitMarkingDayInProgress && day.state === ProgressState.NotStarted
        ? ProgressState.InProgress
        : day.state;

      return { key: day.key, state: newDayState };
    }

    return day;
  });

  const hasAnyProgress = getHasAnyProgress(newDaysProgressToStore);

  const getShouldMarkSessionAsComplete = () => {
    if (shouldOmitFirstDayCheck) {
      return newDaysProgressToStore
        .slice(1, newDaysProgressToStore.length)
        .every((day) => day.state === ProgressState.Completed);
    }

    if (shouldOmitLastDayCheck) {
      return newDaysProgressToStore
        .slice(0, newDaysProgressToStore.length - 1)
        .every((day) => day.state === ProgressState.Completed);
    }

    return newDaysProgressToStore.every(
      (day) => day.state === ProgressState.Completed
    );
  };

  const shouldMarkSessionAsComplete = getShouldMarkSessionAsComplete();

  const newSessionState = shouldMarkSessionAsComplete
    ? ProgressState.Completed
    : !hasAnyProgress
    ? ProgressState.NotStarted
    : initialState;

  const result = await updateDoc(
    doc(database, sessionProgressCollection, sessionProgressId),
    {
      days: newDaysProgressToStore,
      lastUpdated: Date.now(),
      ...(allowOverwriteSessionState && {
        state: newSessionState,
      }),
    } as Partial<SessionProgress>
  );

  if (newSessionState === ProgressState.Completed) {
    const { planId, userId } = data;
    const q = query(
      collection(database, sessionProgressCollection),
      where("userId", "==", userId),
      where("planId", "==", planId),
      where("state", "==", 2)
    );
    const progressSnapshot = await getDocs(q);
    const progressData = progressSnapshot.docs.map((snapshotDoc) =>
      snapshotDoc.data()
    );
    const completedSessions = [
      ...new Set(progressData.map((item) => item.sessionId)),
    ];

    if (getState) {
      const sessions = getSessionsByPlanId(getState() as RootState, planId);

      if (completedSessions.length === sessions.length) {
        asyncLogEvent(events.PLAN_COMPLETED, {
          userId,
          planId,
        });
        await updatePlanProgressData(planId, env, "numUsersCompleted");
      } else if (completedSessions.length === 1) {
        await updatePlanProgressData(planId, env, "numUsersCompletedSome");
      }
    }
  }

  return result;
}

export async function getPlanProgressesByUserId(
  userId: string,
  env: Environment
) {
  const sessionProgressCollection = getSessionProgressCollection(env);
  const planProgressCollection = getPlanProgressCollection(env);

  const q = query(
    collection(database, planProgressCollection),
    where("userId", "==", userId)
  );

  const querySnapshot = await getDocs(q);
  const planProgress = querySnapshot.docs.map((snapshotDoc) => ({
    data: snapshotDoc.data(),
    id: snapshotDoc.id,
  })) as {
    id: string;
    data: GetPlanProgressesData;
  }[];

  const planProgressData = await Promise.all(
    planProgress.map(async ({ id, data }) => {
      const sessionsPromises = data.sessions.map((session) =>
        getDoc(doc(database, sessionProgressCollection, session.id))
      );

      const sessions = await Promise.all(sessionsPromises).then(
        (docs) =>
          docs.map((d) => ({
            ...d.data(),
            sessionProgressId: d.id,
            planProgressId: id,
          })) as SessionProgress[]
      );

      return { planProgressId: id, ...data, sessions };
    })
  );

  return planProgressData;
}
