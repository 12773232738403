import styled, { css } from "styled-components/native";
import { Animated } from "react-native";
import { Image } from "expo-image";

import { isWeb, windowHeight, windowWidth } from "~/utils/platform";
import { Icon } from "~/components/icon";
import { spacers } from "~/styles/theme";
import { getWebContainerStyle } from "~/utils/web";

const absoluteStyle = css`
  position: absolute;
  bottom: 0px;
  left: 0px;
  top: 0px;
  right: 0px;
`;

export const SplashScreenContainer = styled.View`
  flex: 1;
`;

export const BackgroundWrapper = styled.View`
  ${absoluteStyle}
`;

export const AnimatedWrapper = styled(Animated.View)`
  ${absoluteStyle}
`;

export const Container = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const LogoContent = styled(Animated.View)`
  align-items: center;
  justify-content: center;
`;

export const BackgroundImage = styled(Image)`
  width: ${isWeb ? "100%" : `${windowWidth}px`};
  height: ${isWeb ? "100%" : `${windowHeight}px`};
`;

export const StyledIcon = styled(Icon)``;

export const Content = styled.View`
  padding-horizontal: ${spacers.ss8};
  padding-bottom: 20px;
  justify-content: space-between;
`;

export const ButtonBox = styled.View`
  ${getWebContainerStyle(340)}
`;
