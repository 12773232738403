import * as React from "react";
import {
  NativeSyntheticEvent,
  NativeScrollEvent,
  Animated,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { gestureHandlerRootHOC } from "react-native-gesture-handler";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useFocusEffect } from "@react-navigation/native";

import { BibleHeader } from "~/components/bible-header";
import { Screen } from "~/components/screen";
import { HeaderBar } from "~/components/header-bar";
import { colors } from "~/styles/theme";

import { getScriptureData } from "~/utils/strings";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getChapters } from "~/state/bible/actions";
import { setCurrentChapter } from "~/state/bible/slice";
import { asyncLogEvent, events } from "~/utils/analytics";
import {
  getBibleAbbreviation,
  getCurrentBookName,
  getCurrentChaptersOrdered,
  getCurrentBook,
  getCurrentChapter,
} from "~/state/bible/selectors";
import { getBibleHighlights, getBibleNotes } from "~/state/bible-notes";
import { isWeb } from "~/utils/platform";
import { BibleStackParamList } from "~/navigation/bible-stack";

import { Header } from "./components/header";
import { Content as ChapterContent } from "./components/content";
import { Arrow } from "./components/arrow";
import { GestureContainer } from "./components/gesture-container";
import { Container, Content, CenteredBox, ErrorBox, ErrorText } from "./styles";
import { messages } from "./intl";
import { getUserId } from "~/state/user/selectors";
import { useTutorial } from "~/state/tutorial";
import { getTutorialStep } from "~/state/tutorial/selectors";

const THRESHOLD = 100;

type Props = NativeStackScreenProps<BibleStackParamList, "bible">;

export const Bible = gestureHandlerRootHOC(({ navigation, route }: Props) => {
  const scripture = route?.params?.scripture;
  const showBackButton = route?.params?.showBackButton;
  const isSession = route?.params?.isSession;

  // Prevent keeping the scroll position when navigating between the tabs
  useFocusEffect(
    React.useCallback(() => {
      return scrollToTop;
    }, [])
  );

  const {
    chapter: linkedChapter,
    verses: linkedVerses,
    bookId: linkedBookId,
    scriptureText,
  } = getScriptureData(scripture);
  const { showTutorial } = useTutorial();

  const [isCompact, setIsCompact] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [showArrows, setShowArrows] = React.useState(true);

  const fadeContent = React.useRef(new Animated.Value(0)).current;
  const fadeArrows = React.useRef(new Animated.Value(1)).current;
  const scrollRef = React.useRef<null | ScrollView>();

  const dispatch = useAppDispatch();
  const bibleName = useAppSelector(getBibleAbbreviation);
  const bookId = useAppSelector(getCurrentBook);
  const chapterNumber = useAppSelector(getCurrentChapter);
  const bookName = useAppSelector(getCurrentBookName);
  const chapters = useAppSelector(getCurrentChaptersOrdered);
  const userId = useAppSelector(getUserId);
  const tutorialStep = useAppSelector((state) =>
    getTutorialStep(state, "bible")
  );

  const chapter =
    chapters.find(({ position }) => position === chapterNumber) || chapters[0];
  const verses = chapter?.verses || [];
  const chapterReference = hasError
    ? messages.select
    : chapter?.reference || "";
  const title = chapter?.title || "";
  const isLinked = linkedBookId === bookId && linkedChapter === chapterNumber;

  const previousChapter = React.useMemo(() => {
    if (!chapter?.position || chapter.position <= 1) {
      return;
    }

    return chapter.position - 1;
  }, [chapter]);

  const nextChapter = React.useMemo(() => {
    if (!chapter?.position || chapter.position >= chapters.length) {
      return;
    }

    return chapter.position + 1;
  }, [chapter, chapters]);

  const resetParams = () => navigation.setParams({ scripture: "" });

  const refreshData = React.useCallback(() => {
    setHasError(false);
    dispatch(getChapters({ bookId, onError: () => {} }));
    dispatch(getBibleNotes({ onSuccess: () => {}, onError: () => {} }));
    dispatch(getBibleHighlights());
  }, [dispatch, bookId]);

  React.useEffect(() => {
    refreshData();
  }, [refreshData, bookId]);

  React.useEffect(() => {
    showTutorial(tutorialStep);
  }, [showTutorial, tutorialStep]);

  // Refresh when coming from the scripture link
  React.useEffect(() => {
    if (linkedBookId && linkedChapter) {
      dispatch(
        setCurrentChapter({
          bookId: linkedBookId,
          chapterId: linkedChapter,
        })
      );
    }
  }, [dispatch, linkedBookId, linkedChapter]);

  React.useEffect(() => {
    if (chapters) {
      fadeIn(fadeContent);
    }
  }, [chapters, fadeContent]);

  const fadeIn = (animation: Animated.Value) =>
    Animated.timing(animation, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start();

  const fadeOut = (animation: Animated.Value, callback: () => void) =>
    Animated.timing(animation, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start(callback);

  const handleScroll = React.useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (showArrows && !isWeb) {
        fadeOut(fadeArrows, () => setShowArrows(false));
      }
      const position = e.nativeEvent.contentOffset.y;
      if (position < THRESHOLD && isCompact) {
        setIsCompact(false);
        return;
      }
      if (position >= THRESHOLD && !isCompact) {
        setIsCompact(true);
        return;
      }
    },
    [isCompact, showArrows, setShowArrows, fadeArrows]
  );

  const handleShowArrows = React.useCallback(() => {
    if (!showArrows) {
      fadeIn(fadeArrows);
      setShowArrows(true);
    }
  }, [fadeArrows, showArrows, setShowArrows]);

  const scrollToTop = () => scrollRef.current?.scrollTo({ y: 0 });

  const handlePrev = () => {
    if (!previousChapter) {
      return;
    }

    asyncLogEvent(events.BIBLE_NAVIGATION, {
      bookId,
      chapterId: previousChapter,
    });

    fadeOut(fadeContent, () => {
      resetParams();
      scrollToTop();
      fadeIn(fadeContent);
      dispatch(
        setCurrentChapter({
          bookId,
          chapterId: previousChapter,
        })
      );
    });
  };

  const handleNext = () => {
    if (!nextChapter) {
      return;
    }

    asyncLogEvent(events.BIBLE_NAVIGATION, {
      bookId,
      chapterId: nextChapter,
    });

    fadeOut(fadeContent, () => {
      resetParams();
      scrollToTop();
      fadeIn(fadeContent);
      dispatch(
        setCurrentChapter({
          bookId,
          chapterId: nextChapter,
        })
      );
    });
  };

  const scrollToPosition = (position: number) => {
    setIsCompact(true);
    const verticalPosition = Math.max(0, position);
    scrollRef.current?.scrollTo({
      x: 0,
      y: verticalPosition,
      animated: false,
    });
  };

  const renderContent = () => {
    return (
      <Content
        onScroll={handleScroll}
        onTouchEnd={handleShowArrows}
        scrollEventThrottle={160}
        ref={scrollRef}
      >
        {verses.length && chapterNumber === 1 ? (
          <Header bookName={bookName} />
        ) : null}
        <ChapterContent
          title={title}
          bookId={bookId}
          chapterId={chapterNumber}
          verses={verses}
          scrollToPosition={scrollToPosition}
          highlightedVerses={isLinked ? linkedVerses : undefined}
        />
      </Content>
    );
  };

  const renderContainer = () => {
    return isWeb ? (
      renderContent()
    ) : (
      <GestureContainer onSwipeLeft={handleNext} onSwipeRight={handlePrev}>
        <Container style={[{ opacity: fadeContent }]}>
          {renderContent()}
        </Container>
      </GestureContainer>
    );
  };

  return (
    <Screen screenName="bible">
      <CenteredBox>
        {hasError ? (
          <ErrorBox>
            <ErrorText>{messages.errorTitle}</ErrorText>
          </ErrorBox>
        ) : (
          <ActivityIndicator />
        )}
      </CenteredBox>

      {isWeb && <HeaderBar iconColor={colors.black} hasShadow={false} />}

      <BibleHeader
        book={chapterReference}
        version={bibleName}
        isCompact={isCompact}
        scripture={scriptureText}
        showBackButton={showBackButton}
        isSession={isSession}
        onPress={() => setIsCompact(false)}
      />

      {renderContainer()}

      {previousChapter && showArrows ? (
        <Arrow opacity={fadeArrows} onPress={handlePrev} isLeft />
      ) : null}
      {nextChapter && showArrows ? (
        <Arrow opacity={fadeArrows} onPress={handleNext} showTutorial />
      ) : null}
    </Screen>
  );
});
