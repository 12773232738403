import styled, { css } from "styled-components/native";
import { ImageBackground } from "expo-image";

import { Text2, Text3, Text4 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

const itemStyle = css`
  height: 200px;
  width: 300px;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray200};
  margin-right: ${spacers.ss6};
`;

export const Container = styled.View`
  margin-vertical: ${spacers.ss6};
  margin-horizontal: ${spacers.ss6};
`;

export const Content = styled.ScrollView``;

export const SectionTitle = styled(Text3)`
  text-transform: uppercase;
  font-family: SFProDisplayMedium;
  letter-spacing: 0.5px;
  margin-bottom: ${spacers.ss6};
`;

export const Item = styled.View`
  ${itemStyle}
`;

export const ImageItem = styled(ImageBackground)`
  ${itemStyle}
`;

export const Text = styled(Text4)`
  font-family: SFProDisplayBold;
  color: ${colors.white};
  margin-bottom: ${spacers.ss4};
`;

export const ItemButton = styled.TouchableOpacity`
  background-color: ${colors.gray600};
  padding: ${spacers.ss4} ${spacers.ss6};
  border-radius: 20px;
`;

export const ButtonText = styled(Text2)`
  color: ${colors.white};
  font-family: SFProDisplayMedium;
`;
