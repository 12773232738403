import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";
import { getCurrentLocale } from "~/utils/translation";

import { tutorialSteps } from "./constants";

const getState = (state: RootState) => state.tutorial;

export const getGroupsSeen = createSelector(
  getState,
  (state) => state.groupsSeen || []
);

export const getContent = createSelector(
  getState,
  (state) => state.content || {}
);

export const getIsTutorialEnabled = createSelector(
  getState,
  (state) => !!state.shouldShowTutorial
);

export const getShouldShowTutorial = createSelector(
  [getGroupsSeen, getIsTutorialEnabled, (_, props) => props],
  (groupsSeen, isEnabled, groupId = "home") => {
    return (
      isEnabled && !groupsSeen.includes(groupId) && !groupsSeen.includes("all")
    );
  }
);

export const getTutorialStep = createSelector(
  [getShouldShowTutorial, (_, props) => props],
  (shouldShowTutorial, groupId) => {
    if (!shouldShowTutorial) {
      return null;
    }

    return tutorialSteps.find(({ group }) => group === groupId)?.id;
  }
);

export const getTutorialStepById = createSelector(
  [getShouldShowTutorial, getContent, (_, props) => props],
  (shouldShowTutorial, content, stepId) => {
    if (!shouldShowTutorial) {
      return null;
    }

    const currentLocale = getCurrentLocale();

    const data = tutorialSteps.find(({ id }) => id === stepId);
    const textContent = content[stepId]?.[currentLocale];
    const text = textContent || data?.text;

    if (!data || !text) {
      return null;
    }

    return {
      ...data,
      text,
    };
  }
);
