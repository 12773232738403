import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { persistStore, persistCombineReducers } from "reduxjs-toolkit-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getPersistConfig } from "redux-deep-persist";

import {
  bibleSaga,
  bibleDownloadSaga,
  bibleDeleteSaga,
  bibleChangeSaga,
  bibleSlice,
  chaptersSaga,
} from "./bible";
import {
  bibleNotesSlice,
  addBibleNotesSaga,
  getBibleNotesSaga,
  deleteBibleNoteSaga,
  editBibleNoteSaga,
  addBibleHighlightSaga,
  getBibleHighlightsSaga,
} from "./bible-notes";
import {
  personalNotesSlice,
  addPersonalNotesSaga,
  getPersonalNotesSaga,
  editPersonalNoteSaga,
  deletePersonalNoteSaga,
} from "./personal-notes";
import { checklistSlice } from "./checklist";
import {
  chatSlice,
  createMessageSaga,
  createMessagesSaga,
  deleteMessageSaga,
} from "./chat";
import { getNotesCountSaga } from "./notes";
import { startupSaga, startupSlice, isUserReadySaga } from "./startup";
import { configSlice, remoteConfigSaga } from "./config";
import {
  authSaga,
  loginSaga,
  logoutSaga,
  preLogoutSaga,
  signupSaga,
  saveProfileSaga,
  editProfileSaga,
  loginWithAppleSaga,
  loginWithGoogleSaga,
  verifyEmailSaga,
  sendEmailVerificationSaga,
  forgottenPasswordSaga,
  updatePasswordSaga,
  deleteAccountSaga,
  userSlice,
  changePasswordSaga,
  resetAPICacheSaga,
  emailVerificationPollingSaga,
  changeEmailSaga,
  setTutorialGroupsSeenSaga,
} from "./user";
import { flamelinkSaga, flamelinkSlice } from "./flamelink";
import { updateUsersSaga, usersSlice } from "./users";
import {
  groupsSlice,
  fetchGroupsSaga,
  createGroupSaga,
  editGroupSaga,
  addGroupResourceSaga,
  removeGroupResourceSaga,
  joinGroupSaga,
  removeGroupMemberSaga,
  changeRoleSaga,
  setLastActivitySaga,
  deleteGroupSaga,
  refreshInviteCodeSaga,
} from "./groups";
import { saveTokenSaga } from "./push-notifications";
import {
  inAppNotificationsSlice,
  getInAppNotificationsSaga,
} from "./in-app-notifications";
import {
  contentProgressApi,
  contentProgressSaga,
  contentProgressSlice,
} from "./content-progress";
import {
  sessionNotesApi,
  getSessionNotesSaga,
  addSessionNoteSaga,
  editSessionNoteSaga,
  deleteSessionNoteSaga,
  getSessionNotesMetaSaga,
} from "./session-notes";
import { notesSlice } from "./notes";
import { settingsSlice } from "./settings";
import { prayerRequestsApi } from "./prayer-requests";
import { uiSlice } from "./ui";
import { tutorialSlice } from "./tutorial";
import { appFeedbackSaga } from "./app-feedback";
import { isAndroid } from "~/utils/platform";
import { logError } from "~/utils/logger";
import { getWebTokenSaga } from "./web-notifications";
import { getWalkthroughSaga } from "./tutorial";
import {
  paymentsSlice,
  getSubscriptionSaga,
  updateSubscriptionsSaga,
  deleteSubscriptionsSaga,
  getPaymentMethodsSaga,
  getPaymentIntentsSaga,
  addPaymentMethodSaga,
  updateDefaulPaymentMethodSaga,
} from "./payments";

const sagas = {
  bibleSaga,
  bibleDownloadSaga,
  bibleDeleteSaga,
  bibleChangeSaga,
  chaptersSaga,
  startupSaga,
  isUserReadySaga,
  authSaga,
  loginSaga,
  logoutSaga,
  preLogoutSaga,
  signupSaga,
  saveProfileSaga,
  editProfileSaga,
  loginWithAppleSaga,
  loginWithGoogleSaga,
  verifyEmailSaga,
  sendEmailVerificationSaga,
  forgottenPasswordSaga,
  updatePasswordSaga,
  changePasswordSaga,
  setTutorialGroupsSeenSaga,
  flamelinkSaga,
  fetchGroupsSaga,
  createGroupSaga,
  editGroupSaga,
  addGroupResourceSaga,
  removeGroupResourceSaga,
  joinGroupSaga,
  deleteGroupSaga,
  refreshInviteCodeSaga,
  removeGroupMemberSaga,
  changeRoleSaga,
  setLastActivitySaga,
  updateUsersSaga,
  createMessageSaga,
  createMessagesSaga,
  deleteMessageSaga,
  saveTokenSaga,
  addBibleNotesSaga,
  deleteBibleNoteSaga,
  getBibleNotesSaga,
  editBibleNoteSaga,
  addPersonalNotesSaga,
  getPersonalNotesSaga,
  editPersonalNoteSaga,
  deletePersonalNoteSaga,
  getSessionNotesSaga,
  addSessionNoteSaga,
  editSessionNoteSaga,
  deleteSessionNoteSaga,
  deleteAccountSaga,
  addBibleHighlightSaga,
  getBibleHighlightsSaga,
  resetAPICacheSaga,
  emailVerificationPollingSaga,
  contentProgressSaga,
  appFeedbackSaga,
  changeEmailSaga,
  getNotesCountSaga,
  getSessionNotesMetaSaga,
  getWebTokenSaga,
  getWalkthroughSaga,
  getInAppNotificationsSaga,
  remoteConfigSaga,
  getSubscriptionSaga,
  updateSubscriptionsSaga,
  deleteSubscriptionsSaga,
  getPaymentMethodsSaga,
  getPaymentIntentsSaga,
  addPaymentMethodSaga,
  updateDefaulPaymentMethodSaga,
};

const blacklist = isAndroid
  ? ["startup", "flamelink", "bible.books"]
  : ["startup"];

function* rootSaga() {
  yield all(Object.values(sagas).map((saga) => fork(saga)));
}

const sagaMiddleware = createSagaMiddleware({
  onError: (error: Error, info: { sagaStack: string }) => {
    logError(error, info?.sagaStack);
  },
});

export const rootReducer = {
  bible: bibleSlice.reducer,
  chat: chatSlice.reducer,
  checklist: checklistSlice.reducer,
  config: configSlice.reducer,
  startup: startupSlice.reducer,
  user: userSlice.reducer,
  users: usersSlice.reducer,
  groups: groupsSlice.reducer,
  flamelink: flamelinkSlice.reducer,
  notes: notesSlice.reducer,
  bibleNotes: bibleNotesSlice.reducer,
  personalNotes: personalNotesSlice.reducer,
  settings: settingsSlice.reducer,
  payments: paymentsSlice.reducer,
  contentProgress: contentProgressSlice.reducer,
  tutorial: tutorialSlice.reducer,
  inAppNotifications: inAppNotificationsSlice.reducer,
  ui: uiSlice.reducer,
  [contentProgressApi.reducerPath]: contentProgressApi.reducer,
  [sessionNotesApi.reducerPath]: sessionNotesApi.reducer,
  [prayerRequestsApi.reducerPath]: prayerRequestsApi.reducer,
};

const storeAPIMiddlewares = [
  contentProgressApi.middleware,
  sessionNotesApi.middleware,
  prayerRequestsApi.middleware,
];

const persistConfig = getPersistConfig({
  key: "root",
  storage: AsyncStorage,
  blacklist,
  rootReducer: () => rootReducer,
});

const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleWare) => {
    return [
      ...getDefaultMiddleWare({
        thunk: true,
        serializableCheck: false,
        immutableCheck: { warnAfter: 1028 },
      })
        .prepend(sagaMiddleware)
        .concat(storeAPIMiddlewares),
    ];
  },
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
setupListeners(store.dispatch);

export interface RootState {
  bible: ReturnType<typeof bibleSlice.reducer>;
  chat: ReturnType<typeof chatSlice.reducer>;
  checklist: ReturnType<typeof checklistSlice.reducer>;
  config: ReturnType<typeof configSlice.reducer>;
  startup: ReturnType<typeof startupSlice.reducer>;
  user: ReturnType<typeof userSlice.reducer>;
  users: ReturnType<typeof usersSlice.reducer>;
  groups: ReturnType<typeof groupsSlice.reducer>;
  flamelink: ReturnType<typeof flamelinkSlice.reducer>;
  notes: ReturnType<typeof notesSlice.reducer>;
  bibleNotes: ReturnType<typeof bibleNotesSlice.reducer>;
  personalNotes: ReturnType<typeof personalNotesSlice.reducer>;
  payments: ReturnType<typeof paymentsSlice.reducer>;
  settings: ReturnType<typeof settingsSlice.reducer>;
  contentProgress: ReturnType<typeof contentProgressSlice.reducer>;
  inAppNotifications: ReturnType<typeof inAppNotificationsSlice.reducer>;
  tutorial: ReturnType<typeof tutorialSlice.reducer>;
  ui: ReturnType<typeof uiSlice.reducer>;
  [contentProgressApi.reducerPath]: ReturnType<
    typeof contentProgressApi.reducer
  >;
  [sessionNotesApi.reducerPath]: ReturnType<typeof sessionNotesApi.reducer>;
  [prayerRequestsApi.reducerPath]: ReturnType<typeof prayerRequestsApi.reducer>;
}

export type AppDispatch = typeof store.dispatch;
