import React from "react";
import { useNavigation } from "@react-navigation/native";
import {
  WebBrowserPresentationStyle,
  openBrowserAsync,
} from "expo-web-browser";

import * as routes from "~/constants/routes";
import { ListButton } from "~/components/button";
import { makeExternalLinks } from "~/utils/external-links";

import { AccountHeader } from "../../../header";
import { Container, Title, Section, SectionTitle } from "../../styles";
import { messages as generalMessages } from "../../intl";
import { Screens } from "../../../../types";

import { Description } from "./styles";
import { messages } from "./intl";

interface Props {
  onBackPress: () => void;
  setScreen: (screen: Screens) => void;
}

export const GivingIntro = React.memo<Props>(({ setScreen, onBackPress }) => {
  const navigation = useNavigation();

  const handleContact = React.useCallback(() => {
    navigation.goBack();
    setTimeout(() => {
      navigation.navigate(routes.helpAndFeedback);
    }, 1000);
  }, [navigation]);

  const onPressFAQs = React.useCallback(async () => {
    await openBrowserAsync(
      `${makeExternalLinks().FAQS}#p_C806E0B3CF904FA98A4CBB8B11995F91`,
      {
        presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
      }
    );
  }, []);

  return (
    <Container>
      <AccountHeader onBackPress={onBackPress} onPress={onPressFAQs} />

      <Title>{generalMessages.giving}</Title>

      <Description>{messages.description}</Description>

      <Section>
        <SectionTitle>{messages.info}</SectionTitle>

        <ListButton
          text={generalMessages.history}
          onPress={() => setScreen(Screens.History)}
        />
        <ListButton
          text={generalMessages.manage}
          onPress={() => setScreen(Screens.Manage)}
        />
      </Section>
      <Section>
        <SectionTitle>{messages.help}</SectionTitle>

        <ListButton text={generalMessages.faq} onPress={onPressFAQs} />
        <ListButton text={generalMessages.contact} onPress={handleContact} />
      </Section>
    </Container>
  );
});
