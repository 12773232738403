export const messages = {
  logout: {
    id: "profile.logout",
    defaultMessage: "Logout",
  },
  login: {
    id: "profile.login",
    defaultMessage: "Log in",
  },
  cancel: {
    id: "profile.cancel",
    defaultMessage: "Cancel",
  },
};
