import axios from "axios";
import { call, select, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { getDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { database } from "<config>/firebase";

import { getSubscriptionsCollection } from "~/constants/collections";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { handleError } from "~/utils/logger";

import {
  addPaymentMethod,
  deleteSubscription,
  getPaymentIntentsAction,
  getPaymentMethodsAction,
  getSubscriptions,
  updateDefaultPaymentMethod,
  updateSubscription,
} from "./actions";
import {
  Subscription,
  GetSubscriptionAction,
  UpdateSubscriptionAction,
  GetPaymentMethodsAction,
  UpdateDefaulPaymentMethodAction,
  AddPaymentMethodAction,
  GetPaymentIntentsAction,
} from "./types";
import {
  resetSubscription,
  setPaymentIntents,
  setPaymentMethods,
  setSubscription,
  setSubscriptionPaymentMethod,
} from "./slice";
import { WEB_APP_URL } from "~/constants";
import { getApiHeaders } from "./utils";

export function* getSubscriptionFn({
  payload: { onSuccess, onError },
}: GetSubscriptionAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const userId = yield select(getUserId);
    const subscriptionsCollection = getSubscriptionsCollection(env);

    const snapshot = yield call(
      // @ts-ignore
      getDoc,
      doc(database, subscriptionsCollection, userId)
    );

    if (!snapshot.exists()) {
      yield put(setSubscription(null));
      yield call(onError);
      return;
    }

    const data = snapshot.data() as Subscription;

    yield put(setSubscription(data));
    yield call(onSuccess);
  } catch (e) {
    yield put(setSubscription(null));
    yield call(handleError, e);
    yield call(onError);
  }
}

export function* updateSubscriptionFn({
  payload: { amount, frequency, email, chargeOn, onSuccess, onError },
}: UpdateSubscriptionAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const userId = yield select(getUserId);
    const subscriptionsCollection = getSubscriptionsCollection(env);

    const snapshot = yield call(
      // @ts-ignore
      getDoc,
      doc(database, subscriptionsCollection, userId)
    );

    if (!snapshot.exists()) {
      yield call(onError);
      return;
    }

    const existingData = snapshot.data() as Subscription;

    const data = {
      ...existingData,
      amount: amount || existingData.amount,
      frequency: frequency || existingData.frequency,
      email: email || existingData.email,
      chargeOn: chargeOn || existingData.chargeOn,
    };

    yield call(
      // @ts-ignore
      updateDoc,
      doc(database, subscriptionsCollection, userId),
      data
    );

    yield put(setSubscription(data));
    yield call(onSuccess);
  } catch (e) {
    yield call(handleError, e);
    yield call(onError);
  }
}

export function* deleteSubscriptionFn({
  payload: { onSuccess, onError },
}: UpdateSubscriptionAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const userId = yield select(getUserId);
    const subscriptionsCollection = getSubscriptionsCollection(env);

    yield call(
      // @ts-ignore
      deleteDoc,
      doc(database, subscriptionsCollection, userId)
    );

    yield put(resetSubscription());
    yield call(onSuccess);
  } catch (e) {
    yield call(handleError, e);
    yield call(onError);
  }
}

export function* getPaymentIntentsFn({
  payload: { onSuccess, onError },
}: GetPaymentIntentsAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const headers = yield call(getApiHeaders);
    const userId = yield select(getUserId);

    const response = yield call(
      axios.post,
      `${WEB_APP_URL}/api/payments/transactions-${env}`,
      {
        limit: 100,
        uid: userId,
      },
      headers
    );

    if (Array.isArray(response?.data?.data)) {
      yield put(setPaymentIntents(response.data.data));
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } else {
      throw new Error("Payment intents retrieval failed");
    }
  } catch (e) {
    yield call(handleError, e);
    if (typeof onError === "function") {
      onError();
    }
  }
}

export function* getPaymentMethodsFn({
  payload: { onSuccess, onError },
}: GetPaymentMethodsAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const headers = yield call(getApiHeaders);
    const userId = yield select(getUserId);

    const response = yield call(
      axios.post,
      `${WEB_APP_URL}/api/payments/methods-${env}`,
      {
        limit: 100,
        uid: userId,
      },
      headers
    );

    if (Array.isArray(response?.data?.data)) {
      yield put(setPaymentMethods(response.data.data));
      yield call(onSuccess);
    } else {
      throw new Error("Payment methods retrieval failed");
    }
  } catch (e) {
    yield call(handleError, e);
    yield call(onError);
  }
}

export function* addPaymentMethodFn({
  payload: { onSuccess, onError },
}: AddPaymentMethodAction): SagaIterator {}

export function* updateDefaultPaymentMethodFn({
  payload: { methodId, onSuccess, onError },
}: UpdateDefaulPaymentMethodAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const subscriptionsCollection = getSubscriptionsCollection(env);
    const userId = yield select(getUserId);

    yield call(
      // @ts-ignore
      updateDoc,
      doc(database, subscriptionsCollection, userId),
      { paymentMethodId: methodId }
    );

    yield put(setSubscriptionPaymentMethod(methodId));
    yield call(onSuccess);
  } catch (e) {
    yield call(handleError, e);
    yield call(onError);
  }
}

export const getSubscriptionSaga = function* () {
  yield takeLatest(getSubscriptions.type, getSubscriptionFn);
};

export const updateSubscriptionsSaga = function* () {
  yield takeLatest(updateSubscription.type, updateSubscriptionFn);
};

export const deleteSubscriptionsSaga = function* () {
  yield takeLatest(deleteSubscription.type, deleteSubscriptionFn);
};

export const getPaymentIntentsSaga = function* () {
  yield takeLatest(getPaymentIntentsAction.type, getPaymentIntentsFn);
};

export const getPaymentMethodsSaga = function* () {
  yield takeLatest(getPaymentMethodsAction.type, getPaymentMethodsFn);
};

export const addPaymentMethodSaga = function* () {
  yield takeLatest(addPaymentMethod.type, addPaymentMethodFn);
};

export const updateDefaulPaymentMethodSaga = function* () {
  yield takeLatest(
    updateDefaultPaymentMethod.type,
    updateDefaultPaymentMethodFn
  );
};
