import styled from "styled-components/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { colors, spacers } from "~/styles/theme";
import { IconSizes } from "~/components/icon";
import { Text3 } from "~/components/text";
import { isWeb } from "~/utils/platform";

export const Container = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingBottom: 120,
  },
})`
  ${isWeb ? `padding-top: ${spacers.ss8}` : ""}
`;

export const DeleteAccountButtonContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  position: absolute;
  padding: 20px 0px 40px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

export const DeleteAccountButton = styled.TouchableOpacity`
  flex-direction: row;
`;

export const DeleteAccountButtonIcon = styled(MaterialCommunityIcons).attrs({
  name: "delete-forever",
  color: colors.red500,
  size: IconSizes.SMedium,
})``;

export const DeleteAccountButtonText = styled(Text3)`
  color: ${colors.red500};
`;

export const WebContainer = styled.View`
  flex-direction: row;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  flex: 1;
`;

export const NavigationContainer = styled.View`
  width: 300px;
`;

export const ContentContainer = styled.View`
  flex: 1;
`;
