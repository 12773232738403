import { StyleSheet } from "react-native";
import styled from "styled-components/native";

import { colors } from "~/styles/theme";
import { Text1 } from "../text";

export const styles = StyleSheet.create({
  locationInput: {
    fontSize: 17,
    lineHeight: 23,
    color: colors.black,
    fontFamily: "SFProDisplay",
    paddingHorizontal: 12,
    paddingVertical: 12,
    width: "100%",
    borderBottomWidth: 0.5,
    borderBottomColor: colors.gray450,
  },
  locationActive: {
    borderBottomColor: colors.primaryBlue,
    borderBottomWidth: 2,
  },
});

const fontStyle = {
  color: "#000",
  fontFamily: "SFPro",
  fontSize: "16.5px",
  padding: "16px 12px",
};

export const webStyles = {
  placeholder: (provided: any) => ({
    ...provided,
    ...fontStyle,
    color: "rgba(0, 0, 0, 0.54)",
  }),
  input: (provided: any) => ({
    ...provided,
    ...fontStyle,
  }),
  option: (provided: any) => ({
    ...provided,
    ...fontStyle,
    fontSize: "14px",
    backgroundColor: "white",
    padding: "10px 12px",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    ...fontStyle,
  }),
  control: (provided: any) => ({
    ...provided,
    borderBottom: "1px solid rgba(0, 0, 0, 0.26)",
  }),
};

export const Label = styled(Text1)`
  color: ${colors.primaryBlue};
  margin-left: 12px;
  margin-top: 13px;
  margin-bottom: -8px;
`;
