import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { Resources } from "~/components/resources";
import { SafeScrollView } from "~/components/safe-scroll-view";
import { useAlert } from "~/components/alert";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getShouldAskForFeedback } from "~/state/user/selectors";
import * as routes from "~/constants/routes";
import { PostAuthParamList } from "~/navigation/post-auth";
import { genericMessages } from "~/constants/intl";
import { formatMessage } from "~/utils/translation";

import { HomeHeader } from "./home-header";
import { messages } from "./intl";
import {
  dismissAskForFeedback,
  openAskForFeedback,
} from "~/state/app-feedback";
import { getTutorialStep } from "~/state/tutorial/selectors";
import { useTutorial } from "~/state/tutorial";

type NavigationProp = NativeStackScreenProps<
  PostAuthParamList,
  "settings.modal"
>;

export const Home = () => {
  const dispatch = useAppDispatch();
  const shouldAskForFeedback = useAppSelector(getShouldAskForFeedback);
  const tutorialStep = useAppSelector((state) =>
    getTutorialStep(state, "home")
  );

  const navigation = useNavigation<NavigationProp["navigation"]>();
  const { showAlert } = useAlert();
  const { showTutorial } = useTutorial();

  React.useEffect(() => {
    if (shouldAskForFeedback) {
      setTimeout(
        () =>
          showAlert(formatMessage(messages.appFeedback), "", [
            {
              text: formatMessage(genericMessages.ok),
              onPress: () => {
                dispatch(openAskForFeedback());
                navigation.navigate(routes.settingsModal, {
                  screen: routes.appFeedback,
                });
              },
            },
            {
              text: formatMessage(genericMessages.cancel),
              onPress: () => {
                dispatch(dismissAskForFeedback());
              },
            },
          ]),
        3000
      );
    }
  }, [dispatch, navigation, shouldAskForFeedback]);

  React.useEffect(() => {
    showTutorial(tutorialStep);
  }, [showTutorial, tutorialStep]);

  return (
    <SafeScrollView
      alwaysBounceVertical={false}
      bounces={false}
      overScrollMode="never"
      showsVerticalScrollIndicator={false}
      hasFixedHeader
    >
      <HomeHeader />

      <Resources />
    </SafeScrollView>
  );
};
