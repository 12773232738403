import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InAppNotificationsState,
  NotificationData,
  DismissNotificationData,
} from "./types";
import { MAX_NOTIFICATION_TIMES } from "./constants";

const initialState: InAppNotificationsState = {
  data: undefined,
  isVisible: false,
  blacklist: [],
  notificationsSeen: {},
};

export const inAppNotificationsSlice = createSlice({
  name: "in-app-notifications",
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<NotificationData>) => {
      state.data = action.payload;
      state.isVisible = true;
    },
    dismissNotification: (
      state,
      action: PayloadAction<DismissNotificationData>
    ) => {
      if (!state.data?.id) {
        state.isVisible = false;
        state.data = undefined;
        return;
      }
      const hasBeenOpen = !!action.payload.isOpen;
      const notificationSeen = state.notificationsSeen?.[state.data.id] || 0;
      const maxTimes = state.data.maxTimes || MAX_NOTIFICATION_TIMES;

      if (hasBeenOpen || notificationSeen >= maxTimes - 1) {
        const updatedBlacklist = [...state.blacklist, state.data.id];
        state.blacklist = updatedBlacklist;
      }
      state.notificationsSeen = {
        ...state.notificationsSeen,
        [state.data.id]: hasBeenOpen ? maxTimes : notificationSeen + 1,
      };

      state.isVisible = false;
      state.data = undefined;
    },
  },
});

export const { setNotification, dismissNotification } =
  inAppNotificationsSlice.actions;

export default inAppNotificationsSlice.reducer;
