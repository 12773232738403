export const messages = {
  title: {
    id: "profile.title",
    defaultMessage: "Profile",
  },
  contactInfo: {
    id: "profile.contact-info",
    defaultMessage: "Contact Info",
  },
  password: {
    id: "profile.password",
    defaultMessage: "Password",
  },
  changePassword: {
    id: "profile.change-password",
    defaultMessage: "Change Password",
  },
  emailSettings: {
    id: "profile.email-settings",
    defaultMessage: "Promotional Emails",
  },
  emailOptOut: {
    id: "profile.email-opt-out",
    defaultMessage: "Keep me updated",
  },
  namePlaceholder: {
    id: "profile.name-placeholder",
    defaultMessage: "Enter your name",
  },
};
