type Action<T> = {
  type: string;
  payload: T;
  meta: any;
  error: any;
};

type Callbacks = {
  onSuccess: () => void;
  onError: () => void;
};

type CallbacksOptional = {
  onSuccess?: () => void;
  onError?: () => void;
};

export type Transaction = {
  id: string;
  object?: string;
  amount: number;
  amount_capturable?: number;
  amount_received?: number;
  automatic_payment_methods?: {
    enabled: boolean;
  };
  canceled_at?: number;
  cancellation_reason?: string;
  confirmation_method?: string;
  created: number;
  currency: string;
  customer?: string;
  description?: string;
  invoice?: string;
  livemode?: boolean;
  payment_method?: string;
  status?: string;
  setup_future_usage?: string;
};

export type PaymentMethod = {
  id: string;
  object: string;
  card: {
    brand: string;
    country: string;
    display_brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
    created: number;
    livemode: boolean;
    type: string;
  };
};

export type TransactionSection = {
  title: number;
  data: Transaction[];
};

export enum SubscriptionFrequency {
  MONTHLY,
  QUARTERLY,
  YEARLY,
}

export enum PlatformPayment {
  APPLE,
  GOOGLE,
}

export type Subscription = {
  userId: string;
  customerId_stg: string;
  customerId_prd: string;
  amount: number;
  currency: string;
  frequency: SubscriptionFrequency;
  lastChargeAttempt?: number;
  lastChargeSuccess?: number;
  createdAt?: number;
  email: string;
  chargeOn: number;
  paymentMethodId: string;
};

export type PaymentState = {
  subscription: Subscription | null;
  paymentIntents: Transaction[];
  paymentMethods: PaymentMethod[];
  currency: string;
};

export type UpdateSubscription = {
  amount?: number;
  currency?: string;
  frequency?: SubscriptionFrequency;
  email?: string;
  chargeOn?: number;
};

export type UpdateDefaultPaymentMethod = {
  methodId: string;
};

export type GetSubscriptionPayload = Callbacks;
export type DeleteSubscriptionPayload = Callbacks;
export type UpdateSubscriptionPayload = Callbacks & UpdateSubscription;
export type GetPaymentMethodsPayload = Callbacks;
export type AddPaymentMethodPayload = Callbacks;
export type GetPaymentIntentsPayload = CallbacksOptional;
export type UpdateDefaulPaymentMethodPayload = Callbacks &
  UpdateDefaultPaymentMethod;

export type GetSubscriptionAction = Action<GetSubscriptionPayload>;
export type UpdateSubscriptionAction = Action<UpdateSubscriptionPayload>;
export type DeleteSubscriptionAction = Action<DeleteSubscriptionPayload>;
export type GetPaymentMethodsAction = Action<GetPaymentMethodsPayload>;
export type AddPaymentMethodAction = Action<AddPaymentMethodPayload>;
export type UpdateDefaulPaymentMethodAction =
  Action<UpdateDefaulPaymentMethodPayload>;
export type GetPaymentIntentsAction = Action<GetPaymentIntentsPayload>;
