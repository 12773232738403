import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import { TutorialContext } from "./context";
import { tutorialSteps } from "./constants";
import { useAppSelector } from "../hooks";
import { getGroupsSeen } from "./selectors";

export const useTutorial = () => {
  const { stepId, setStepId, position, setPosition, setIsDark, skipTutorial } =
    React.useContext(TutorialContext);

  const navigation = useNavigation();
  const groupsSeen = useAppSelector(getGroupsSeen);

  const showTutorial = React.useCallback(
    (id?: string | null, delay = 600) => {
      if (id) {
        const currentRoute =
          navigation.getState().routes[navigation.getState().index].name;

        const currentStep = tutorialSteps.find((step) => step.id === id);
        const routeAllowed = currentStep?.route;
        const order = currentStep?.order || 0;
        const isHorizontal = !currentStep?.isHorizontal;

        if (!currentStep || (routeAllowed && currentRoute !== routeAllowed)) {
          return;
        }

        const remainingSteps = tutorialSteps
          .filter((step) => {
            const hasBeenSeen = !groupsSeen.includes(step.group);
            if (isHorizontal) {
              return hasBeenSeen;
            }
            return hasBeenSeen && step.route === currentRoute;
          })
          .map((step) => step.order);

        if (Math.min(...remainingSteps) < order) {
          return;
        }

        setTimeout(() => {
          setStepId(id);
        }, delay);
      }
    },
    [setStepId, navigation, groupsSeen]
  );

  return {
    stepId,
    setStepId,
    showTutorial,
    position,
    setPosition,
    setIsDark,
    skipTutorial,
  };
};
