import React from "react";
import { Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { ResourceEmpty } from "~/components/resource-empty";

import { ResourceItem } from "../resource-item";
import { ResourceItemSkeleton } from "../resource-item/skeleton";
import {
  WebResourcesContainer,
  ResourcesTopBar,
  ResourcesTopBarTitle,
  EmptyResourcesContainer,
  WebResourcesList,
} from "../../styles";
import { messages } from "../../intl";
import {
  GetPlanProgressesByUserIdData,
  SessionProgress,
} from "~/state/content-progress";

interface Props {
  showLoading: boolean;
  progressData: SessionProgress | undefined;
  planProgressesData: GetPlanProgressesByUserIdData[] | undefined;
}

export function ResourcesContent({
  showLoading,
  progressData,
  planProgressesData,
}: Props) {
  const navigation = useNavigation();

  const handleResourcePress = React.useCallback(
    (id: string) => {
      navigation.navigate(routes.plan, { planId: id, backToHome: true });
    },
    [navigation]
  );

  return (
    <WebResourcesContainer>
      <ResourcesTopBar>
        <ResourcesTopBarTitle>{messages.resourcesTitle}</ResourcesTopBarTitle>
      </ResourcesTopBar>

      {showLoading ? (
        <ResourceItemSkeleton />
      ) : progressData && planProgressesData?.length ? (
        <WebResourcesList>
          {planProgressesData.map(({ planId, activeSession }) => (
            <Pressable
              onPress={() => handleResourcePress(planId)}
              key={`${planId}${activeSession?.sessionId}`}
            >
              <ResourceItem
                planId={activeSession?.planId ?? ""}
                sessionId={activeSession?.sessionId ?? ""}
              />
            </Pressable>
          ))}
        </WebResourcesList>
      ) : (
        <EmptyResourcesContainer>
          <ResourceEmpty text={messages.resourcesEmptyDescription} />
        </EmptyResourcesContainer>
      )}
    </WebResourcesContainer>
  );
}
