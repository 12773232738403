import * as React from "react";
import { messages } from "~/screens/session/intl";
import { formatMessage } from "~/utils/translation";
import { Question as QuestionType } from "~/state/flamelink";
import { getLastUpdated } from "~/screens/notes/utils";
import { useAppDispatch } from "~/state/hooks";
import { addSessionNote, editSessionNote } from "~/state/session-notes/actions";
import { isWeb } from "~/utils/platform";

import {
  AnswerButtonText,
  AnswerCancelButton,
  AnswerDivider,
  AnswerDoneButton,
  AnswerHeaderContainer,
  AnswerInput,
  AnswerQuestionContent,
  AnswerTitle,
  LastUpdated,
} from "./styles";

interface AnswerProps {
  question: QuestionType;
  onClose(): void;
  sessionId: string;
  answer?: string;
  noteId?: string;
  lastUpdated?: number;
}

export const Answer: React.FC<AnswerProps> = ({
  question,
  onClose,
  sessionId,
  answer,
  noteId,
  lastUpdated,
}) => {
  const [value, onChangeText] = React.useState(answer ?? "");
  const lastUpdatedText = getLastUpdated(lastUpdated);
  const dispatch = useAppDispatch();

  const handleOnSubmitEditing = React.useCallback(() => {
    const data = {
      sessionId,
      questionId: question.uniqueKey,
      question: question.question,
      note: value,
      noteId,
    };
    if (noteId) {
      dispatch(editSessionNote({ ...data, noteId }));
    } else {
      dispatch(addSessionNote(data));
    }

    onClose();
  }, [dispatch, onClose, question, sessionId, value, noteId]);

  return (
    <>
      <AnswerHeaderContainer>
        <AnswerCancelButton onPress={onClose}>
          <AnswerButtonText>
            {formatMessage(messages.answerCancelButton)}
          </AnswerButtonText>
        </AnswerCancelButton>

        <AnswerTitle>{formatMessage(messages.question)}</AnswerTitle>

        <AnswerDoneButton onPress={handleOnSubmitEditing}>
          <AnswerButtonText>
            {formatMessage(messages.answerDoneButton)}
          </AnswerButtonText>
        </AnswerDoneButton>
      </AnswerHeaderContainer>

      <AnswerDivider />

      <AnswerQuestionContent>
        {question.question
          ?.replace(/\[(.*?)\]\(.*?\)/g, "$1")
          .replace(/\*\*(.*?)\*\*/g, "$1")
          .replace(/_([^_]+)_/g, "$1")}
      </AnswerQuestionContent>

      {lastUpdated ? <LastUpdated>{lastUpdatedText}</LastUpdated> : null}

      <AnswerDivider />

      <AnswerInput
        multiline={true}
        numberOfLines={10}
        placeholder={formatMessage(messages.answerInputPlaceholder)}
        autoFocus
        value={value}
        onChangeText={(text: string) => onChangeText(text)}
        textAlignVertical="top"
        // outline required to be passed inline to work for web
        {...(isWeb ? { style: { outline: "none" } } : {})}
      />
    </>
  );
};
