import { Action } from "@reduxjs/toolkit";
import type { DownloadProgressData } from "~/utils/file-system";
import type { DocumentReference } from "firebase/firestore";
import type { Tuple } from "~/types/utils";

export interface VolumeOverview {
  sectionTitle: string;
  heading: string;
  title: string;
  content: string;
  scripture: string;
  facilitatorGuideMedia: string[];
  footnotes: Footnote[];
  mediaRepeater: MediaContentRepeater[];
  youWillNeed?: string;
  gettingReady?: string;
}

export interface Question {
  question: string;
  uniqueKey: string;
  disableAnswer?: boolean;
}

export interface VideoRef extends DocumentReference {
  _key: {
    path: {
      segments: string[];
    };
  };
}

export type Footnote = {
  fieldKey: string;
  uniqueKey: string;
  footnote: string;
};

export type WhatWeBelieveSection = {
  whatWeBelieveSectionTitle?: string;
  title?: string;
  mediaRepeater: MediaContentRepeater[];
  conclusion?: string;
  footnotes: Footnote[];
};

export type BubbleResource = {
  volumeId: string;
  sessionId: string;
  volumeIndex: number;
  sessionIndex: number;
};

export type IntroBlock = {
  faithVerseDescription: string;
  faithVerseTitle: string;
  faithVerse: string;
  questionOfTheDay: string;
  faithFactTitle: string;
  questionOfTheDayTitle: string;
  faithFact: string;
  foundationForFaithTitle: string;
  foundationForFaith: string;
  foundationForFaithFootnotes: Footnote[];
};

export interface WatchSection {
  intro: string;
  introQuestions: ContentRepeater[];
  sectionTitle: string;
  video: Tuple<VideoRef, 1>;
  questions: ContentRepeater[];
  watchVideoMedia: string[];
  footnotes: Footnote[];
}

export interface SubsectionRepeater {
  title: string;
  scripture: string;
  intro: string;
}

export interface ConsiderSubsectionRepeater extends SubsectionRepeater {
  body: string;
  conclusion: string;
  questionRepeater: ContentRepeater[];
  footnotes: Footnote[];
  mediaRepeater: MediaContentRepeater[];
}

export interface ReflectSubsectionRepeater extends SubsectionRepeater {
  questions: ContentRepeater[];
}

export interface ConsiderSection {
  intro: string;
  sectionTitle: string;
  questions: ContentRepeater[];
  conclusion: string;
  subsectionRepeater: ConsiderSubsectionRepeater[];
  whatWeBelieve: WhatWeBelieveSection;
  footnotes: Footnote[];
  youWillNeed?: string;
  gettingReady?: string;
}

export interface ReflectSection {
  sectionTitle: string;
  content: string;
  questions: ContentRepeater[];
  subsectionRepeater: ReflectSubsectionRepeater[];
  footnotes: Footnote[];
}

export interface ContentRepeater {
  content: string;
  question: string;
  uniqueKey: string;
  footnotes: Footnote[];
}

export interface MediaContentRepeater {
  introduction?: string;
  thought?: string;
  media: string[];
  content?: string;
  questionRepeater?: ContentRepeater[];
  conclusion?: string;
}

export interface EngageSection {
  sectionTitle: string;
  intro: string;
  contentRepeater: ContentRepeater[];
  content: string;
  whatWeBelieve: WhatWeBelieveSection;
  groupActivity: {
    sectionTitle: string;
    subtitle?: string;
    youWillNeed?: string;
    gettingReady?: string;
    content?: string;
    media?: string[];
    conclusion?: string;
    questions?: ContentRepeater[];
  };
  conclusion: string;
  conclusionRepeater: ContentRepeater[];
  footnotes: Footnote[];
}

export interface ListenToGodSection {
  sectionTitle: string;
  intro: string;
  questions: ContentRepeater[];
  personalReflectionQuestions: {
    sectionTitle: string;
    disclaimer: string;
    intro: string;
    questionRepeater: ContentRepeater[];
    conclusion: string;
  };
  prayerRequests: {
    sectionTitle: string;
    content: string;
    intro: string;
  };
  whatWeBelieve: WhatWeBelieveSection;
  activate: {
    activateMedia: string[];
    content: string;
    sectionTitle: string;
    question: string;
    questions: ContentRepeater[];
  };
  conclusion: string;
  footnotes: Footnote[];
}

export interface FlamelinkMedia {
  id: string;
  contentType: string;
  file: string;
  type: string;
  url: string;
}

export interface Day {
  id: string;
  title: string;
  content: string;
  scripture: string;
  questions: Question[];
}

export enum SectionOrder {
  VolumeOverview = "volumeOverview",
  Watch = "watch",
  Consider = "consider",
  Engage = "engage",
  ListenToGod = "listenToGod",
  Reflect = "reflect",
}

export interface Session {
  id: string;
  title: string;
  days: string[];
  lessons: string[];
  volumeOverview: VolumeOverview;
  watch: WatchSection;
  sectionOrder: Array<SectionOrder>;
  consider: ConsiderSection;
  reflect: ReflectSection;
  engage: EngageSection;
  listenToGod: ListenToGodSection;
}

export interface Volume {
  id: string;
  title: string;
  sessions: Session[];
  volumeNumber: Number;
}

export interface Plan {
  id: string;
  planTitle: string;
  volumes: Volume[];
  volumesHidden: boolean;
  cover: Tuple<FlamelinkMedia, 1>;
  logo: Tuple<FlamelinkMedia, 1>;
}

export type Filters = Array<[string, string, string] | []>;

export interface FlamelinkBaseQueryParams {
  schemaKey: string;
  filters?: Filters;
  equalTo?: string;
  populate?: boolean;
  fields?: Array<string>;
}

export type Content = {
  order: number;
  id: string;
  title: string;
  status?: string;
};

export type AudiencesContent = Content & {
  plans: string[];
  locale: string;
  displayOrder: number;
  carouselTitle?: string;
  displayTitle: string;
};

export type PlansContent = Content & {
  volumes: string[];
  cover: string[];
  logo: string[];
  volumesHidden: boolean;
  description: string;
  displayTitle: string;
};

export type VolumesContent = Content & {
  sessions: string[];
  volumeNumber: number;
  hidden: boolean;
  additionalAssets: string[];
};

export type SessionsContent = Content & {
  days: string[];
  lessons: string[];
  sessions: string[];
  downloads: string[];
  volumeHeading: string;
};

export type LessonsBasicContent = Content & {
  heading: string;
};

export type PersonalDevotionsContent = Content & {
  questions: ContentRepeater[];
  disclaimer: string;
  content: string;
  scriptureHeading: string;
  scriptureDisclaimer: string;
  scripture: string;
  heading: string;
  conclusion: string;
  introBlocks: IntroBlock;
  downloads: string[];
  mediaRepeater: MediaContentRepeater[];
};

export enum LessonSectionOrder {
  LessonOverview = "lessonOverview",
  BibleLesson = "bibleLesson",
  FaithFact = "faithFact",
  SessionPreparation = "sessionPreparation",
  PreserviceActivities = "preserviceActivitities",
  RulesReview = "rulesReview",
  Game = "game",
  Worship = "worship",
  TeachingTime = "teachingTime",
  PrayerResponse = "prayerResponse",
  GroupConnection = "groupConnection",
  BibleLessonRecap = "bibleLessonRecap",
  GroupDiscussion = "groupDiscussion",
  LiveIt = "liveIt",
  FaithVerseReview = "faithVerseReview",
  ActivityPage = "activityPage",
  Learning = "learning",
  BibleTime = "bibleTime",
  BibleTimeContinued = "bibleTimeContinued",
  Dismissal = "dismissal",
  Application = "application",
  CreativeTime = "creativeTime",
}

export interface LessonsContent extends Content {
  heading: string;
  shortLessonDescription: string;
  sectionOrder: Array<LessonSectionOrder>;
  overview: string;
  downloads: [];
  lessonOverview?: {
    overview: string;
    media: string[];
    content: string;
    conclusion: string;
    footnotes: Footnote[];
  };
  faithFactContent?: {
    faithFactIntro: string;
    faithFactMedia: string[];
    faithFactConclusion: string;
    conclusionMedia: string[];
    faithFactVerse: string;
  };
  preparingForTheSession?: {
    sectionTitle?: string;
    preparationTime?: string;
    youWillNeed: {
      preserviceActivities: string;
      largeGroup: string;
      smallGroupActivities: string;
      smallGroup: string;
    };
    gettingReady: {
      preserviceActivities: string;
      largeGroup: string;
      smallGroupActivities: string;
      smallGroup: string;
    };
    conclusion: string;
    facilitatorGuideMedia: string[];
    footnotes: Footnote[];
  };
  preserviceActivities?: {
    sectionTitle?: string;
    warmUp?: string;
    warmUpMedia: string[];
    snackTime?: {
      sectionTitle?: string;
      subtitle?: string;
      snackIntroMedia: string[];
      introduction?: string;
      snackMedia: string[];
      conclusion?: string;
    };
    freePlay?: string;
    transition?: string;
  };
  rulesReview?: {
    sectionTitle: string;
    intro: string;
    rulesVideoMedia: string[];
    conclusion?: string;
    conclusionMedia: string[];
  };
  game?: {
    sectionTitle?: string;
    subheading?: string;
    name?: string;
    youWillNeed?: string;
    gettingReady?: string;
    gameSlideADescription?: string;
    gameSlideAMedia?: string[];
    gameSlideAConclusion?: string;
    gameSlideBDescription?: string;
    gameSlideBMedia?: string[];
    gameSlideBConclusion?: string;
    faithVerseFun?: {
      introduction?: string;
      youWillNeed?: string;
      gettingReady?: string;
      thought?: string;
      media: string[];
      content?: string;
      scripture?: string;
      conclusion?: string;
    };
    footnotes: Footnote[];
  };
  worship?: {
    sectionTitle: string;
    introduction?: string;
    content?: string;
    thought?: string;
    prayer?: string;
    conclusion?: string;
    conclusionMedia: string[];
  };
  bibleLesson?: {
    sectionTitle?: string;
    introduction?: string;
    youWillNeed?: string;
    gettingReady?: string;
    prayer?: string;
    bibleLessonVideoIntro?: string;
    bibleLessonIntroVideoMedia: string[];
    bibleLessonStoryIntro?: string;
    bibleLessonStoryVideoMedia: string[];
    bibleLessonStoryConclusion?: string;
    bibleStoryRepeater?: MediaContentRepeater[];
  };
  teachingTime?: {
    sectionTitle?: string;
    youWillNeed?: string;
    gettingReady?: string;
    gettingReadyMedia: string[];
    description?: string;
    teachingPoints?: Array<{
      title?: string;
      slideAThought?: string;
      teachingPointSlideAMedia?: string[];
      description?: string;
      slideBThought?: string;
      teachingPointSlideBMedia?: string[];
      slideBDescription?: string;
      slideCThought?: string;
      teachingPointSlideCMedia?: string[];
      slideCDescription?: string;
      conclusion?: string;
    }>;
  };
  prayerAndResponse?: {
    sectionTitle?: string;
    intro?: string;
    youWillNeed?: string;
    gettingReady?: string;
    description?: string;
    prayerThought?: string;
    prayerMedia: string[];
    content?: string;
    mediaRepeater?: MediaContentRepeater[];
    prayer?: string;
    media: string[];
    conclusion?: string;
    conclusionContent?: string;
    conclusionPrayer?: string;
    conclusionMedia: string[];
    epilogue?: string;
    epilogueMedia: string[];
    postscript?: string;
    salvationPrayer?: string;
    salvationPrayerMedia: string[];
    faithVerse?: {
      title?: string;
      introduction?: string;
      faithVerseVideoMedia: string[];
      scripture: string;
      description?: string;
    };
    faithFact?: {
      title?: string;
      introduction?: string;
      faithFactSlideMedia: string[];
      description?: string;
    };
  };
  groupConnection?: {
    sectionTitle?: string;
    subheading?: string;
    subtitle?: string;
    youWillNeed?: string;
    gettingReady?: string;
    gettingReadyMedia: string[];
    description?: string;
    media?: string[];
    conclusion?: string;
    conclusionMedia?: string[];
    epilogue?: string;
    prayer?: string;
  };
  bibleLessonRecap?: {
    sectionTitle?: string;
    introduction?: string;
    youWillNeed?: string;
    gettingReady?: string;
    description?: string;
    media: string[];
    content?: string;
  };
  groupDiscussion?: {
    sectionTitle?: string;
    introduction?: string;
    gettingReady?: string;
    youWillNeed?: string;
    questions?: Question[];
    content?: string;
    questionRepeater: ContentRepeater[];
    conclusion?: string;
    conclusionMedia: string[];
    prayerRequests?: string;
    prayer?: string;
  };
  liveIt?: {
    sectionTitle: string;
    introduction?: string;
    youWillNeed?: string;
    gettingReady?: string;
    description?: string;
    thought?: string;
    prayer?: string;
  };
  faithVerseReview?: {
    sectionTitle?: string;
    youWillNeed?: string;
    gettingReady?: string;
    gettingReadyMedia: string[];
    introduction?: string;
    faithVerseVideoMedia: string[];
    content?: string;
    faithVerseFun?: string;
    faithVerseFunMedia: string[];
  };
  activityPage?: {
    sectionTitle?: string;
    subheading?: string;
    subtitle?: string;
    youWillNeed?: string;
    gettingReady?: string;
    gettingReadyMedia: string[];
    body?: string;
    mediaRepeater?: MediaContentRepeater[];
    activityMedia: string[];
    conclusion?: string;
  };
  learning?: {
    sectionTitle?: string;
    subheading?: string;
    subtitle?: string;
    youWillNeed?: string;
    gettingReady?: string;
    gettingReadyMedia: string[];
    content?: string;
    learningMedia: string[];
    conclusion?: string;
    conclusionMedia: string[];
    epilogue?: string;
    epilogueMedia: string[];
  };
  bibleTime?: {
    sectionTitle: string;
    introduction?: string;
    bibleTimeIntroMedia: string[];
    content?: string;
    bibleTimeMedia: string[];
    conclusion?: string;
    conclusionMedia: string[];
    epilogue?: string;
  };
  bibleTimeContinued?: {
    sectionTitle: string;
    leaderThought?: string;
    introduction?: string;
    bibleLessonMedia: string[];
    conclusion?: string;
    conclusionMedia: string[];
    epilogue?: string;
    prayer?: string;
    responseTime?: {
      introduction: string;
      prayer: string;
      conclusion: string;
    };
    faithVerse?: {
      introduction?: string;
      faithVerseMedia: string[];
      content?: string;
      prayer?: string;
    };
    closing?: {
      introduction?: string;
      faithFactMedia: string[];
      content?: string;
      faithFactSlideMedia: string[];
      conclusion?: string;
    };
  };
  dismissal?: {
    sectionTitle?: string;
    introduction?: string;
    prayer?: string;
    faithFactMedia: string[];
    faithFactThought?: string;
    faithFactSlideMedia: string[];
    faithFactRecap?: string;
  };
  application?: {
    sectionTitle: string;
    introduction?: string;
    applicationMedia: string[];
    conclusion?: string;
    prayer?: string;
  };
  creativeTime?: {
    sectionTitle?: string;
    subheading?: string;
    subtitle?: string;
    youWillNeed?: string;
    gettingReady?: string;
    gettingReadyMedia: string[];
    introduction?: string;
    media: string[];
    content?: string;
    faithFactSoundMedia: string[];
    faithFactContent?: string;
    faithFactSlideMedia: string[];
    conclusion?: string;
    prayer?: string;
  };
}

export interface SessionFile {
  id: string;
  key: string;
}

export interface FlamelinkMeta {
  audiences: AudiencesContent[];
  plans: PlansContent[];
  sessions: SessionsContent[];
  volumes: VolumesContent[];
  personalDevotion: PersonalDevotionsContent[];
  lessons: LessonsBasicContent[];
  files: FlamelinkFileMeta[];
}

export interface FlamelinkFileMeta {
  id: string;
  title: string;
  downloadTitle: string;
  description: string;
  preview: string;
  file?: string[];
  fileHD?: string[];
  fileUltraHD?: string[];
  important: boolean;
  hidden: boolean;
}

export interface FlamelinkImage {
  planId: string;
  cover?: string;
  logo?: string;
}

export interface FeaturedContent {
  id: string;
  title: string;
  type: "image" | "text";
  text?: string;
  enabled: boolean;
  buttonText?: string;
  file?: string;
  uri?: string;
  plan?: string;
  session?: string;
  order: number;
  locale: string;
}

export interface FlamelinkMediaFile {
  id: string;
  file: string;
  contentType: string;
}

export interface FlamelinkFile {
  id: string;
  title: string;
  downloadTitle: string;
  description: string;
  preview?: FlamelinkMediaFile;
  file?: FlamelinkMediaFile;
  fileHD?: FlamelinkMediaFile;
  fileUltraHD?: FlamelinkMediaFile;
  important: boolean;
  hidden: boolean;
}

export interface FlamelinkState {
  data: FlamelinkMeta | {};
  questions?: Question[];
  images?: FlamelinkImage[];
  downloads: Record<
    string,
    {
      resourceLocation: string;
      size: number;
      title?: string;
      sessionId?: string;
    }
  >;
  /**
   * List of sessions that have been downloaded when using the offline view capability
   */
  downloadedSessions: string[];
  files: FlamelinkFile[];
  sessions: Record<string, Session>;
  lessons: Record<string, LessonsContent>;
  personalDevotions: Record<string, PersonalDevotionsContent>;
  featured: FeaturedContent[];
  featuredFetchTime: number;
}

export interface SessionData {
  data: Session;
}

export interface LessonData {
  data: LessonsContent;
}

export interface PersonalDevotionsData {
  data: PersonalDevotionsContent;
}

export interface FlamelinkData {
  results: FlamelinkMeta;
  questions: Question[];
}

export enum FlamelinkLocales {
  en = "en-US",
  es = "es",
}

export enum FileType {
  PDF = "pdf",
  AUDIO = "audio",
  VIDEO = "video",
  IMAGE = "image",
  PRESENTATION = "presentation",
  WORD = "word",
  UNKNOWN = "unknown",
}

export interface SetDownloadsPayload {
  id: string;
  resourceLocation: string;
  size: number;
  title?: string;
  sessionId?: string;
}

export interface DownloadFilePayload {
  fileId: string;
  fileName: string;
  sessionId: string;
  onUpdate: (progress: DownloadProgressData) => void;
  onError: (error: unknown) => void;
}

export interface DownloadFileAction {
  type: string;
  payload: DownloadFilePayload;
}

export interface DeleteDownloadPayload {
  fileId: string;
  filePath: string;
  sessionId?: string;
}

export interface DeleteDownloadAction {
  type: string;
  payload: DeleteDownloadPayload;
}

export interface OpenFilePayload {
  downloadedResourceLocation?: string;
  fileType: string;
  fileName: string;
}

export interface OpenFileAction {
  type: string;
  payload: OpenFilePayload;
}

export interface DownloadSessionPayload {
  sessionId: string;
  onUpdate: (fileId: string, progress: DownloadProgressData) => void;
}

export interface DownloadVolumePayload {
  volumeId: string;
  onUpdate: (fileId: string, progress: DownloadProgressData) => void;
}

export interface DownloadSessionAction {
  type: string;
  payload: DownloadSessionPayload;
}

export interface DownloadVolumeAction {
  type: string;
  payload: DownloadVolumePayload;
}

export interface DownloadedFile {
  id: string;
  title?: string;
  resourceLocation: string;
  contentType?: string;
  size: number;
  sessionId?: string;
}

export interface LoadFlamelinkFilePayload {
  fileId: string;
}

export interface LoadFilesPayload {
  fileIds: string[];
}

export interface LoadVolumesPayload {
  volumeId: string;
}

export interface LoadPlansPayload {
  planId: string;
}

export interface LoadFlamelinkFilesPayload {
  fileIds: string[];
  onSuccess: (fileData: FlamelinkFile[]) => void;
  onError: () => void;
}

export interface LoadFlamelinkFileAction extends Action {
  payload: LoadFlamelinkFilePayload;
}

export interface LoadFlamelinkAllFilesAction extends Action {
  payload: LoadFilesPayload;
}

export interface LoadVolumeFilesAction extends Action {
  payload: LoadVolumesPayload;
}

export interface LoadPlanFilesAction extends Action {
  payload: LoadPlansPayload;
}

export interface LoadFlamelinkFilesAction extends Action {
  payload: LoadFlamelinkFilesPayload;
}

export interface LoadPersonalDevotionAction extends Action {
  payload: string;
}

export interface LoadSessionAction extends Action {
  payload: string;
}

export interface LoadLessonAction extends Action {
  payload: string;
}

export interface LoadLessonsBySessionIdAction extends Action {
  payload: string;
}
