import { createSelector } from "@reduxjs/toolkit";

import {
  getLibraryFilters,
  getTypeLibraryFilter,
  getAudienceLibraryFilter,
  getLibraryFiltersOptions,
} from "~/state/ui/selectors";
import { UIState } from "~/state/ui/types";
import { removeUndefinedKeys } from "~/utils/arrays";

import { getLanguagesToDisplay } from "../settings";
import { getIsStaging } from "../user/selectors";
import type { RootState } from "../store";
import { initialState } from "./slice";
import {
  FlamelinkMeta,
  PlansContent,
  VolumesContent,
  SessionsContent,
  PersonalDevotionsContent,
  AudiencesContent,
  FlamelinkImage,
  FlamelinkFile,
  LessonsContent,
  LessonsBasicContent,
  FlamelinkState,
  DownloadedFile,
  FlamelinkFileMeta,
  BubbleResource,
  Session,
  Plan,
  FeaturedContent,
} from "./types";
import {
  audienceHasPlansFilter,
  libraryAudienceFilter,
  libraryLanguageFilter,
  libraryTypefilter,
  makeMediaUrl,
  getSessionConditions,
  MediaSize,
} from "./utils";
import { SessionProgressCheckConditions } from "../content-progress";

const getState = (state: RootState) => state.flamelink;

export const getFlamelinkData: (state: RootState) => FlamelinkMeta =
  createSelector(getState, (state) => state?.data || initialState.data);

export const getFlamelinkImages: (
  state: RootState
) => FlamelinkImage[] | undefined = createSelector(
  getState,
  (state) => state?.images || initialState.images
);

export const getFlamelinkFiles: (state: RootState) => FlamelinkFile[] =
  createSelector(getState, (state) => state?.files || initialState.files);

export const getSessionsData: (state: RootState) => FlamelinkState["sessions"] =
  createSelector(getState, (state) => state?.sessions || {});

export const getPersonalDevotionsData: (
  state: RootState
) => FlamelinkState["personalDevotions"] = createSelector(
  getState,
  (state) => state?.personalDevotions || {}
);

export const getLessonsData: (state: RootState) => FlamelinkState["lessons"] =
  createSelector(getState, (state) => state?.lessons || {});

export const getSessionData: (
  state: RootState,
  sessionId: string
) => Session | undefined = createSelector(
  [getSessionsData, (_, props) => props],
  (sessions, sessionId) => sessions[sessionId]
);

export const getPersonalDevotionData: (
  state: RootState,
  personalDevotionId: string
) => PersonalDevotionsContent | undefined = createSelector(
  [getPersonalDevotionsData, (_, props) => props],
  (personalDevotions, personalDevotionId) =>
    personalDevotions[personalDevotionId]
);

export const getLessonData: (
  state: RootState,
  lessonId: string
) => LessonsContent | undefined = createSelector(
  [getLessonsData, (_, props) => props],
  (lessons, lessonId) => lessons[lessonId]
);

export const getPlans: (state: RootState) => PlansContent[] = createSelector(
  getFlamelinkData,
  (data) => data?.plans || []
);

export const getFeaturedContent: (state: RootState) => FeaturedContent[] =
  createSelector(
    [getState, getFlamelinkFiles, getLanguagesToDisplay],
    (state, files, languages) => {
      return (state?.featured || [])
        .filter((item) => item?.enabled && languages.includes(item?.locale))
        .sort((a, b) => b.order - a.order)
        .map((item) => ({
          ...item,
          uri: makeMediaUrl(
            // @ts-ignore
            files.find(({ id }) => item.file?.includes(id))?.file ?? "",
            MediaSize.Small
          ),
        }));
    }
  );

export const getFeaturedFetchTime: (state: RootState) => number =
  createSelector(getState, (state) => state?.featuredFetchTime || 0);

export const getFilteredAudiences: (state: RootState) => AudiencesContent[] =
  createSelector(
    [
      getFlamelinkData,
      getLanguagesToDisplay,
      getPlans,
      getTypeLibraryFilter,
      getAudienceLibraryFilter,
      getLibraryFiltersOptions,
    ],
    (
      data,
      languagesToDisplay,
      plans,
      typeLibraryFilter,
      audienceLibraryFilter,
      libraryFiltersOptions
    ) =>
      data?.audiences
        ?.filter(libraryLanguageFilter(languagesToDisplay))
        .filter(
          libraryAudienceFilter(audienceLibraryFilter, libraryFiltersOptions)
        )
        .map(libraryTypefilter(plans, typeLibraryFilter, libraryFiltersOptions))
        .filter(audienceHasPlansFilter)
        .sort((a, b) => a.displayOrder - b.displayOrder)
  );

export const getPlansBySearchTerm: (
  state: RootState,
  searchTerm: string
) => PlansContent[] = createSelector(
  [getPlans, (_, props) => props],
  (plans, searchTerm) => {
    const filterEmptyTitles = (plan: PlansContent) => plan.title.length > 0;

    if (searchTerm.trim() === "") return plans.filter(filterEmptyTitles);

    return plans
      .filter((plan) =>
        plan.title?.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .filter(filterEmptyTitles);
  }
);

export const getVolumes: (state: RootState) => VolumesContent[] =
  createSelector(getFlamelinkData, (data) => data?.volumes || []);

export const getSessions: (state: RootState) => SessionsContent[] =
  createSelector(getFlamelinkData, (data) => data?.sessions || []);

export const getDevotions: (state: RootState) => PersonalDevotionsContent[] =
  createSelector(getFlamelinkData, (data) => data?.personalDevotion || []);

export const getLessons: (state: RootState) => LessonsBasicContent[] =
  createSelector(getFlamelinkData, (data) => data?.lessons || []);

export const getPlanById: (
  state: RootState,
  planId: string
) => PlansContent | undefined = createSelector(
  [getPlans, (_, props) => props],
  (plans, planId) => plans.find(({ id }) => id === planId)
);

export const getVolumeById: (
  state: RootState,
  planId: string
) => VolumesContent | undefined = createSelector(
  [getVolumes, (_, props) => props],
  (volumes, volumeId) => volumes.find(({ id }) => id === volumeId)
);

export const getAudienceByPlanId: (
  state: RootState,
  planId: string
) => AudiencesContent | undefined = createSelector(
  [getFlamelinkData, (_, props) => props],
  (data, planId) => data?.audiences.find(({ plans }) => plans?.includes(planId))
);

export const getSessionById: (
  state: RootState,
  sessionId: string
) => SessionsContent | undefined = createSelector(
  [getSessions, (_, props) => props],
  (sessions, sessionId) => sessions.find(({ id }) => id === sessionId)
);

export const getDevotionsById: (
  state: RootState,
  dayId: string
) => PersonalDevotionsContent | undefined = createSelector(
  [getDevotions, (_, props) => props],
  (devotions, dayId) => devotions.find(({ id }) => id === dayId)
);

export const getVolumesByPlanId: (
  state: RootState,
  planId: string
) => VolumesContent[] = createSelector(
  [getVolumes, getPlanById],
  (volumes, plan) =>
    volumes
      .filter(({ id }) => plan?.volumes.includes(id))
      .sort((a, b) => a.volumeNumber - b.volumeNumber)
      .map((volume) => ({ ...volume, hidden: plan?.volumesHidden || false })) ||
    []
);

export const getPlanByVolumeId: (
  state: RootState,
  volumeId: string
) => PlansContent | undefined = createSelector(
  [getPlans, (_, props) => props],
  (plans, volumeId) =>
    plans.find(({ volumes = [] }) => volumes.includes(volumeId))
);

export const getDevotionsBySessionId: (
  state: RootState,
  planId: string
) => PersonalDevotionsContent[] = createSelector(
  [getDevotions, getSessionById],
  (devotions = [], session) =>
    session?.days
      ?.map(
        (dayId) =>
          devotions.find(({ id }) => id === dayId) as PersonalDevotionsContent
      )
      .filter((item) => !!item) || []
);

export const getLessonsBySessionId: (
  state: RootState,
  sessionId: string
) => LessonsContent[] = createSelector(
  [getLessons, getSessionById],
  (lessons = [], session) =>
    session?.lessons
      ?.map(
        (lessonId) =>
          lessons.find(({ id }) => id === lessonId) as LessonsContent
      )
      .filter((item) => !!item) || []
);

export const getSessionsBySessionId: (
  state: RootState,
  sessionId: string
) => SessionsContent[] = createSelector(
  [getSessions, getSessionById],
  (sessions = [], session) =>
    session?.sessions
      ?.map(
        (sessionId) =>
          sessions.find(({ id }) => id === sessionId) as SessionsContent
      )
      .filter((item) => !!item) || []
);

export const getSessionFileIds: (
  state: RootState,
  sessionId: string
) => string[] = createSelector(
  [getSessionById, getLessonsBySessionId],
  (session, lessons) => {
    return [
      ...new Set([
        ...(session?.downloads || []),
        ...lessons?.map(({ downloads }) => downloads)?.flat(),
      ]),
    ];
  }
);

const getVolumeFiles = (
  sessions: SessionsContent[],
  lessons: LessonsBasicContent[],
  volume?: VolumesContent
) => {
  const volumeSessions = volume?.sessions || [];
  const sessionsData = sessions.filter(({ id }) => volumeSessions.includes(id));
  const sessionsFiles = sessionsData.map((data) => data.downloads || []).flat();
  const additionalFiles = volume?.additionalAssets || [];
  const lessonsIds = sessionsData.map((data) => data.lessons || []).flat();
  const lessonsData = lessons.filter(({ id }) =>
    lessonsIds.includes(id)
  ) as LessonsContent[];
  const lessonsFiles = lessonsData?.map(({ downloads }) => downloads)?.flat();

  return [...new Set([...sessionsFiles, ...lessonsFiles, ...additionalFiles])];
};

export const getVolumeFileIds: (
  state: RootState,
  volumeId: string
) => string[] = createSelector(
  [getSessions, getLessons, getVolumeById],
  getVolumeFiles
);

export const getPlanFileIds: (state: RootState, planId: string) => string[] =
  createSelector(
    [getSessions, getLessons, getVolumesByPlanId],
    (sessions, lessons, volumes) =>
      volumes.map((volume) => getVolumeFiles(sessions, lessons, volume)).flat()
  );

export const getVolumeAdditionalFileIds: (
  state: RootState,
  volumeId: string
) => string[] = createSelector(
  [getVolumeById],
  (volume) => volume?.additionalAssets || []
);

export const getPlanResources: (
  state: RootState,
  planId: string
) => BubbleResource[] = createSelector([getVolumesByPlanId], (volumes = []) => {
  return volumes
    .map((volume, volumeIndex) =>
      (volume?.sessions || []).map((sessionId, sessionIndex) => ({
        volumeId: volume.id,
        sessionId,
        volumeIndex,
        sessionIndex,
      }))
    )
    .flat();
});

export const getVolumeSessions: (state: RootState, planId: string) => string[] =
  createSelector([getFlamelinkData, (_, props) => props], (data, planId) => {
    const volumes = data?.plans?.find(({ id }) => id === planId)?.volumes || [];

    return volumes?.reduce((acc: string[], volumeId: string) => {
      const volumeSessions =
        data?.volumes.find(({ id }) => id === volumeId)?.sessions || [];
      return [...acc, ...volumeSessions];
    }, []);
  });

export const getVolumeSessionsCount: (
  state: RootState,
  planId: string
) => number = createSelector([getVolumeSessions], (volumeSessions) => {
  return volumeSessions.length || 0;
});

export const getPlanDaysCount: (state: RootState, planId: string) => number =
  createSelector([getFlamelinkData, getVolumeSessions], (data, sessions) => {
    return (
      sessions?.reduce((acc, sessionId) => {
        const session = data?.sessions.find(({ id }) => id === sessionId);
        const sessionDays = session?.days || [];
        const lessonsDays = session?.lessons || [];
        const childSessions = session?.sessions || [];
        const volumeHeadings = session?.volumeHeading ? 1 : 0;

        return (
          acc +
            Number(sessionDays?.length) +
            Number(lessonsDays?.length) +
            Number(childSessions?.length) +
            volumeHeadings || 0
        );
      }, 0) || 0
    );
  });

export const getSessionsByPlanId: (
  state: RootState,
  planId: string
) => string[] = createSelector(
  [getPlanById, (state) => state],
  (plan, state) =>
    plan?.volumes.reduce(
      (acc, volume) =>
        getVolumeById(state, volume)?.sessions?.concat(acc) || acc,
      [] as string[]
    ) || []
);

export const getSessionsByVolumeId: (
  state: RootState,
  volumeId: string
) => string[] = createSelector(
  [getVolumeById],
  (volume) => volume?.sessions || []
);

export const getPlanLogo = createSelector(
  [getFlamelinkImages, (_, planId) => planId],
  (flamelinkImages, planId) => {
    const logo = flamelinkImages?.find((image) => image.planId === planId);

    return makeMediaUrl(logo?.logo ?? "", MediaSize.Medium);
  }
);

export const getPlanCover = createSelector(
  [getFlamelinkImages, (_, planId) => planId],
  (flamelinkImages, planId) => {
    const cover = flamelinkImages?.find((image) => image.planId === planId);

    return makeMediaUrl(cover?.cover ?? "", MediaSize.Large);
  }
);

export const getPlanThumbnail = createSelector(
  [getFlamelinkImages, (_, planId) => planId],
  (flamelinkImages, planId) => {
    const thumbnail = flamelinkImages?.find((image) => image.planId === planId);

    return makeMediaUrl(thumbnail?.cover ?? "", MediaSize.Medium);
  }
);

export const getIsPlanPublished: (state: RootState, planId: string) => boolean =
  createSelector([getPlanById, getIsStaging], (plan, isStaging) => {
    return Boolean(plan?.status === "publish" || isStaging);
  });

export const getIsVolumePublished: (
  state: RootState,
  volumeId: string,
  planId: string
) => boolean = createSelector(
  [getVolumeById, getIsStaging],
  (volume, isStaging) => {
    return true;
    // TODO: future - allow new sessions to be rolled out incrementally by status: publish
    // return Boolean(session?.status === "publish" || isStaging);
  }
);

export const getIsSessionPublished: (
  state: RootState,
  sessionId: string,
  planId: string
) => boolean = createSelector(
  [getSessionById, getIsStaging],
  (session, isStaging) => {
    return true;
    // TODO: future - allow new sessions to be rolled out incrementally by status: publish
    // return Boolean(session?.status === "publish" || isStaging);
  }
);

export const getVolumeBySessionId: (
  state: RootState,
  sessionId: string
) => VolumesContent | undefined = createSelector(
  [getVolumes, (_, props) => props],
  (volumes, sessionId) => {
    return volumes.find(({ sessions }) => sessions.includes(sessionId));
  }
);

export const getVolumeIndexBySessionId: (
  state: RootState,
  sessionId: string
) => number = createSelector(
  [getPlans, getVolumes, (_, props) => props],
  (plans, volumes, sessionId) => {
    const volume = volumes.find(({ sessions }) => sessions.includes(sessionId));

    if (!volume) return 0;

    const plan = plans.find(({ volumes: vols }) => vols.includes(volume?.id));

    const volumeIndex = plan?.volumes.findIndex((vol) => vol === volume?.id);

    return volumeIndex || 0;
  }
);

export const getPlanBySessionId: (
  state: RootState,
  sessionId: string
) => PlansContent | undefined = createSelector(
  [getPlans, getVolumes, (_, props) => props],
  (plans, volumes, sessionId) => {
    const volume = volumes.find(({ sessions }) => sessions.includes(sessionId));

    if (!volume) return;

    return plans.find(({ volumes: planVolumes }) =>
      planVolumes.includes(volume?.id)
    );
  }
);

export const getSessionIndexBySessionId: (
  state: RootState,
  sessionId: string
) => number = createSelector(
  [getVolumes, (_, props) => props],
  (volumes, sessionId) => {
    const volume = volumes.find(({ sessions }) => sessions.includes(sessionId));
    const sessionIndex = volume?.sessions.findIndex(
      (session) => session === sessionId
    );

    return sessionIndex || 0;
  }
);

export const getSessionProgressCheckConditions: (
  state: RootState,
  sessionId: string
) => SessionProgressCheckConditions = createSelector(
  [getSessionById],
  (session) => getSessionConditions(session)
);

export const getDownloads: (state: RootState) => FlamelinkState["downloads"] =
  createSelector([getState], (state) => state.downloads);

export const getHasDownloads: (state: RootState) => boolean = createSelector(
  [getDownloads],
  (downloads) => Object.keys(getDownloads).length > 0
);

export const getDownloadedFileById: (
  state: RootState,
  fileId: string
) => string = createSelector(
  [getDownloads, (_, props) => props],
  (downloads, fileId) => downloads[fileId]?.resourceLocation
);

export const getDownloadedFiles: (state: RootState) => DownloadedFile[] =
  createSelector(
    [getDownloads, getFlamelinkFiles],
    (downloads, flamelinkFiles) => {
      return Object.keys(downloads).map((id) => {
        const file = flamelinkFiles.find(
          ({ file: flamelinkFile }) => flamelinkFile?.id === id
        );
        const fileHD = flamelinkFiles.find(
          ({ fileHD: flamelinkFile }) => flamelinkFile?.id === id
        );
        const fileUltraHD = flamelinkFiles.find(
          ({ fileUltraHD: flamelinkFile }) => flamelinkFile?.id === id
        );

        const title =
          downloads[id].title ||
          file?.title ||
          fileHD?.title ||
          fileUltraHD?.title;
        const contentType =
          file?.file?.contentType ||
          fileHD?.file?.contentType ||
          fileUltraHD?.file?.contentType;

        return {
          id,
          title,
          resourceLocation: downloads[id].resourceLocation,
          contentType,
          size: downloads[id].size,
          sessionId: downloads[id]?.sessionId,
        };
      });
    }
  );

export type DownloadSection = {
  sessionId: string;
  data: DownloadedFile[];
  size: number;
  isBible?: boolean;
};

export const getDownloadedFilesBySession: (
  state: RootState
) => DownloadSection[] = createSelector([getDownloadedFiles], (files) => {
  return (
    files
      .reduce((acc: DownloadSection[], item: DownloadedFile) => {
        const isBible = item.resourceLocation.includes("/bep/bibles/");
        const sessionId = item?.sessionId || "";
        const existingSection = acc.find(
          (section: DownloadSection) => section?.sessionId === sessionId
        );

        if (!existingSection) {
          return [
            ...acc,
            { sessionId, data: [item], size: item.size, isBible },
          ];
        }

        return acc.map((section: DownloadSection) => {
          if (section?.sessionId === sessionId) {
            return {
              sessionId,
              isBible,
              data: [...section.data, item],
              size: section.size + item.size,
            };
          }
          return section;
        });
      }, [])
      // Move Bible section to the bottom
      .sort((a, b) => {
        if (a.isBible && !b.isBible) {
          return 1;
        }
        if (!a.isBible && b.isBible) {
          return -1;
        }
        return 0;
      })
  );
});

export type DownloadSectionPlan = {
  planId: string;
  planTitle: string;
  data: DownloadSection[];
  isBible?: boolean;
};

export const getDownloadedFilesByPlan: (
  state: RootState
) => DownloadSectionPlan[] = createSelector(
  [getDownloadedFilesBySession, getPlans, getVolumes],
  (data, plans, allVolumes) => {
    return data.reduce((acc: DownloadSectionPlan[], section) => {
      const volumeId =
        allVolumes.find(({ sessions }) => sessions.includes(section.sessionId))
          ?.id || "";
      const plan = plans.find(({ volumes = [] }) => volumes.includes(volumeId));
      const planId = plan?.id || "";
      const planTitle = plan?.title || "";

      if (section?.isBible) {
        const existingBibleSection = acc.find((item) => item.isBible);

        if (existingBibleSection) {
          existingBibleSection.data.push(section);
        } else {
          acc.push({
            planId,
            planTitle,
            data: [section],
            isBible: true,
          });
        }
      }

      if (!volumeId || !planId) {
        return acc;
      }

      const existingPlan = acc.find((item) => item.planId === planId);

      if (existingPlan) {
        existingPlan.data.push(section);
      } else {
        acc.push({
          planId,
          planTitle,
          data: [section],
        });
      }

      return acc;
    }, []);
  }
);

export const getFlamelinkFilesById: (
  state: RootState,
  fileId: string
) => FlamelinkFile | undefined = createSelector(
  [getFlamelinkFiles, (_, props) => props],
  (files, fileId) => {
    const file = files.find(({ id }) => id === fileId);

    if (file) {
      return removeUndefinedKeys(file);
    }
  }
);

export const getFlamelinkFilesByIds: (
  state: RootState,
  fileIds: string[]
) => FlamelinkFile[] = createSelector(
  [getFlamelinkFiles, (_, props) => props],
  (files, fileIds) => files.filter(({ id }) => fileIds.includes(id))
);

export const getFlamelinkFilesToProcess: (
  state: RootState,
  fileIds: string[]
) => string[] = createSelector(
  [getFlamelinkFiles, (_, props) => props],
  (files = [], fileIds = []) => {
    const uniqueIds = new Set(
      fileIds.filter((id: string) => !files.some((file) => file.id === id))
    );
    return Array.from(uniqueIds) as string[];
  }
);

const getFlamelinkFilesMeta: (state: RootState) => FlamelinkFileMeta[] =
  createSelector(
    [getFlamelinkData],
    (flamelinkData) => flamelinkData?.files || []
  );

export const getFlamelinkFilesMetaById: (
  state: RootState,
  fileId: string
) => FlamelinkFileMeta | undefined = createSelector(
  [getFlamelinkFilesMeta, (_, props) => props],
  (files, fileId) => files.find(({ id }) => id === fileId)
);

export const getFlamelinkFilesMetaByIds: (
  state: RootState,
  fileIds: string[]
) => FlamelinkFileMeta[] = createSelector(
  [getFlamelinkFilesMeta, (_, props) => props],
  (files = [], fileIds) => files.filter(({ id }) => fileIds.includes(id))
);

/**
 * Returns the file resource location for a given flamelink file id.
 * Necessary to check for different resolutions of the same file.
 */
export const getFileResourceLocationByFlamelinkFileId: (
  state: RootState,
  fileId: string
) => string = createSelector(
  [getDownloads, getFlamelinkFilesById, (_, props) => props, (state) => state],
  (downloads, flamelinkFiles) => {
    return (
      downloads[flamelinkFiles?.file?.id || ""]?.resourceLocation ||
      downloads[flamelinkFiles?.fileHD?.id || ""]?.resourceLocation ||
      downloads[flamelinkFiles?.fileUltraHD?.id || ""]?.resourceLocation
    );
  }
);

export const getFileResourceLocationsBySessionId: (
  state: RootState,
  sessionId: string
) => string[] = createSelector(
  [
    getDownloads,
    getSessionFileIds,
    getFlamelinkFiles,
    (_, props) => props,
    (state) => state,
  ],
  (downloads, sessionFileIds, flamelinkFiles = []) => {
    const sessionFiles = flamelinkFiles
      .filter(({ id }) => sessionFileIds.includes(id))
      .map((file) => {
        if (file) {
          return removeUndefinedKeys(file);
        }
      });

    return sessionFiles.map((files) => {
      return (
        downloads[files?.file?.id || ""]?.resourceLocation ||
        downloads[files?.fileHD?.id || ""]?.resourceLocation ||
        downloads[files?.fileUltraHD?.id || ""]?.resourceLocation
      );
    });
  }
);

export const getCleanSessionFiles: (
  state: RootState,
  sessionId: string
) => string[] = createSelector(
  [getSessionFileIds, getFlamelinkFiles, (_, props) => props, (state) => state],
  (sessionFileIds, flamelinkFiles = []) => {
    const sessionFiles = flamelinkFiles
      .filter(({ id }) => sessionFileIds.includes(id))
      .map((file) => {
        if (file) {
          return removeUndefinedKeys(file);
        }
      });

    return sessionFiles;
  }
);

export const getLibraryFiltersCounter: (state: RootState) => number =
  createSelector(
    [getLibraryFilters, getLanguagesToDisplay, getLibraryFiltersOptions],
    (libraryFilters, languagesToDisplay, libraryFiltersOptions) => {
      if (!libraryFiltersOptions) return 0;

      const hasLanguageFilter =
        languagesToDisplay.length !==
        Object.keys(libraryFiltersOptions.languages).length;

      const initialCounter = hasLanguageFilter ? 1 : 0;

      return Object.keys(libraryFilters).reduce((acc, key) => {
        const k = key as keyof UIState["libraryFilters"];
        const filter = libraryFilters[k];

        const isFilterActive =
          Object.keys(libraryFiltersOptions[k]["en"]).length !== filter.length;

        if (isFilterActive) return acc + filter.length;

        return acc;
      }, initialCounter);
    }
  );

export const getDownloadedSessions: (
  state: RootState
) => FlamelinkState["downloadedSessions"] = createSelector(
  [getState],
  (state) => state.downloadedSessions || []
);

export const getDownloadedPlanSessions: (
  state: RootState,
  planId: string
) => FlamelinkState["downloadedSessions"] = createSelector(
  [getDownloadedSessions, getSessionsByPlanId],
  (downloadedSessions, planSessions) =>
    planSessions.filter((item) => downloadedSessions.includes(item))
);

export const getIsSessionDownloaded: (
  state: RootState,
  sessionId: string
) => boolean = createSelector(
  [getDownloadedSessions, (_, props) => props],
  (downloadedSessions, sessionId) => downloadedSessions.includes(sessionId)
);

export const getIsVolumeDownloaded: (
  state: RootState,
  volumeId: string
) => boolean = createSelector(
  [getDownloadedSessions, getSessionsByVolumeId],
  (downloadedSessions, sessions) =>
    sessions.every((item) => downloadedSessions.includes(item))
);
