import * as React from "react";
import {
  createNativeStackNavigator,
  NativeStackNavigationProp,
} from "@react-navigation/native-stack";

import * as routes from "~/constants/routes";
import { LibraryProvider } from "~/state/library";
import { isMobileDevice } from "~/utils/platform";

import { Home } from "~/screens/home";
import { Messages } from "~/screens/messages";
import { GroupChat } from "~/screens/group-chat";
import { InviteCode } from "~/screens/invite-code";

// Bible
import { Bible } from "~/screens/bible";
import { BooksModal } from "~/screens/books-modal";
import { VersionModal } from "~/screens/version-modal";
import { BibleNoteAdd } from "~/screens/notes-bible-view";

// Groups
import { Groups } from "~/screens/groups";
import { GroupResources } from "~/screens/group-resources";
import { GroupJoin } from "~/screens/group-join";
import { GroupModal } from "~/screens/group-modal";

// Library
import { Library } from "~/screens/library";
import { LibrarySearch } from "~/screens/library-search";
import { LibraryFilters } from "~/screens/library-filters";
import { LibraryProgress } from "~/screens/library-progress";
import { Plan } from "~/screens/plan";
import { Session } from "~/screens/session";
import { SessionsDetails } from "~/screens/session-details";
import { CompleteDay } from "~/screens/complete-day";

// Notes
import { Notes } from "~/screens/notes";
import { SessionNotes } from "~/screens/notes-session";
import { SessionNotesView } from "~/screens/notes-session-view";
import { SessionNoteView } from "~/screens/notes-session-single-view";
import { BibleNotes } from "~/screens/notes-bible";
import { BibleNoteViewEdit } from "~/screens/notes-bible-view";
import { PersonalNotes } from "~/screens/notes-personal";
import {
  PersonalNoteAdd,
  PersonalNoteViewEdit,
} from "~/screens/notes-personal-view";

// Settings
import { Settings } from "~/screens/settings";
import { AppSettings } from "~/screens/app-settings";
import { Profile } from "~/screens/profile";
import { About } from "~/screens/about";
import { HelpAndFeedback } from "~/screens/help-and-feedback";
import { Downloads } from "~/screens/downloads";
import { ChangeName } from "~/screens/change-name";
import { ChangeEmail } from "~/screens/change-email";
import { ChangePassword } from "~/screens/change-password";
import { DeleteAccount } from "~/screens/delete-account";
import { VideoPlayer } from "~/screens/video-player";
import { DevTools } from "~/screens/dev-tools";
import { AppFeedback } from "~/screens/app-feedback";
import { Legal } from "~/screens/legal";

import { CookiePolicy } from "~/screens/cookie-policy";
import { PayItForwardScreen } from "~/screens/pay-it-forward";
import { WebLanding } from "~/screens/landing-web";
import { useAppSelector } from "~/state/hooks";
import { getAppLanguage } from "~/state/settings";

export type UsePostAuthNavigation = NativeStackNavigationProp<any>;

const Stack = createNativeStackNavigator();

export const PostAuthStack = React.memo(() => {
  const appLanguage = useAppSelector(getAppLanguage);

  if (isMobileDevice) {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen name={routes.home} component={WebLanding} />
        <Stack.Screen name={routes.inviteCode} component={InviteCode} />
      </Stack.Navigator>
    );
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      key={appLanguage}
    >
      <Stack.Screen name={routes.home} component={Home} />

      <Stack.Screen name={routes.messages} component={Messages} />
      <Stack.Screen name={routes.groupChat} component={GroupChat} />
      <Stack.Screen name={routes.inviteCode} component={InviteCode} />

      {/* Bible */}
      <Stack.Screen name={routes.bible} component={Bible} />
      <Stack.Screen name={routes.bibleNoteAdd} component={BibleNoteAdd} />
      <Stack.Screen name={routes.booksModal} component={BooksModal} />
      <Stack.Screen name={routes.versionModal} component={VersionModal} />

      {/* Groups */}
      <Stack.Screen name={routes.groups} component={Groups} />
      <Stack.Screen name={routes.groupResources} component={GroupResources} />
      <Stack.Screen name={routes.groupJoin} component={GroupJoin} />
      <Stack.Screen name={routes.groupModal} component={GroupModal} />

      {/* Library */}

      <Stack.Screen name={routes.library} component={Library} />
      <Stack.Screen name={routes.librarySearch} component={LibrarySearch} />
      <Stack.Screen name={routes.libraryFilters} component={LibraryFilters} />
      <Stack.Screen name={routes.libraryProgress} component={LibraryProgress} />
      <Stack.Screen name={routes.plan} component={Plan} />
      <Stack.Screen name={routes.session} component={Session} />
      <Stack.Screen name={routes.completeDay} component={CompleteDay} />

      {/* Notes */}
      <Stack.Screen name={routes.notes} component={Notes} />
      <Stack.Screen name={routes.sessionNotes} component={SessionNotes} />
      <Stack.Screen
        name={routes.sessionNotesView}
        component={SessionNotesView}
      />
      <Stack.Screen name={routes.sessionNoteView} component={SessionNoteView} />
      <Stack.Screen name={routes.bibleNotes} component={BibleNotes} />
      <Stack.Screen
        name={routes.bibleNoteViewEdit}
        component={BibleNoteViewEdit}
      />
      <Stack.Screen name={routes.personalNotes} component={PersonalNotes} />
      <Stack.Screen
        name={routes.personalNotesAdd}
        component={PersonalNoteAdd}
      />
      <Stack.Screen
        name={routes.personalNotesViewEdit}
        component={PersonalNoteViewEdit}
      />

      {/* Settings */}
      <Stack.Screen name={routes.settings} component={Settings} />
      <Stack.Screen name={routes.settingsModal} component={Settings} />
      <Stack.Screen name={routes.appSettings} component={AppSettings} />
      <Stack.Screen name={routes.profile} component={Profile} />
      <Stack.Screen name={routes.about} component={About} />
      <Stack.Screen name={routes.helpAndFeedback} component={HelpAndFeedback} />
      <Stack.Screen name={routes.downloads} component={Downloads} />
      <Stack.Screen name={routes.changeName} component={ChangeName} />
      <Stack.Screen name={routes.changeEmail} component={ChangeEmail} />
      <Stack.Screen name={routes.devTools} component={DevTools} />
      <Stack.Screen name={routes.changePassword} component={ChangePassword} />
      <Stack.Screen name={routes.deleteAccount} component={DeleteAccount} />
      <Stack.Screen name={routes.videoPlayer} component={VideoPlayer} />
      <Stack.Screen name={routes.appFeedback} component={AppFeedback} />
      <Stack.Screen name={routes.legal} component={Legal} />

      <Stack.Screen name={routes.cookiePolicy} component={CookiePolicy} />
      <Stack.Screen name={routes.giving} component={PayItForwardScreen} />
    </Stack.Navigator>
  );
});

export const PostAuthNavigator = () => {
  return (
    <LibraryProvider>
      <PostAuthStack />
    </LibraryProvider>
  );
};
