import React from "react";
import { GestureResponderEvent } from "react-native";

import { formatMessage } from "~/utils/translation";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  getVideoQuality,
  setVideoQuality,
  VideoQuality,
} from "~/state/settings";
import { asyncLogEvent, events } from "~/utils/analytics";
import { getOptionText } from "~/utils/options";
import { useActionSheet } from "~/components/action-sheet";
import type { SelectVideoQualityParams } from "./types";
import { messages } from "./intl";

export const useVideoQuality = () => {
  const { showActionSheetWithOptions } = useActionSheet();
  const dispatch = useAppDispatch();
  const videoQuality = useAppSelector(getVideoQuality);

  const selectVideoQuality = (
    params?: SelectVideoQualityParams,
    event?: GestureResponderEvent,
    position?: DOMRect
  ) => {
    const qualityOptions = [
      { message: formatMessage(messages.normal), type: VideoQuality.Normal },
      { message: formatMessage(messages.high), type: VideoQuality.High },
      {
        message: formatMessage(messages.ultraHigh),
        type: VideoQuality.UltraHigh,
      },
      { message: formatMessage(messages.auto) },
    ].map(getOptionText<VideoQuality>(videoQuality));

    showActionSheetWithOptions(
      {
        title: formatMessage(messages.title),
        options: [...qualityOptions, formatMessage(messages.cancel)],
        cancelButtonIndex: 4,
      },
      (selectedOption?: number) => {
        switch (selectedOption) {
          case 0:
            params?.saveSettings &&
              dispatch(setVideoQuality(VideoQuality.Normal));
            params?.onQualitySet?.(VideoQuality.Normal);
            asyncLogEvent(events.VIDEO_QUALITY, {
              quality: VideoQuality.Normal,
            });

            break;
          case 1:
            params?.saveSettings &&
              dispatch(setVideoQuality(VideoQuality.High));
            params?.onQualitySet?.(VideoQuality.High);
            asyncLogEvent(events.VIDEO_QUALITY, { quality: VideoQuality.High });

            break;
          case 2:
            params?.saveSettings &&
              dispatch(setVideoQuality(VideoQuality.UltraHigh));
            params?.onQualitySet?.(VideoQuality.UltraHigh);
            asyncLogEvent(events.VIDEO_QUALITY, {
              quality: VideoQuality.UltraHigh,
            });

            break;
          case 3:
            params?.saveSettings && dispatch(setVideoQuality(undefined));
            params?.onQualitySet?.(undefined);
            asyncLogEvent(events.VIDEO_QUALITY, { quality: undefined });

            break;
        }
      },
      // @ts-ignore
      event,
      position
    );
  };

  const selectedVideoQuality = React.useMemo(() => {
    if (videoQuality) {
      const translationKey = `${videoQuality}` as keyof typeof messages;

      return formatMessage(messages[translationKey]);
    }

    return formatMessage(messages.auto);
  }, [videoQuality]);

  return { selectVideoQuality, selectedVideoQuality };
};
