import React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  openBrowserAsync,
  WebBrowserPresentationStyle,
} from "expo-web-browser";

import { SettingsStackParamList } from "~/navigation/settings-stack";
import { ModalHeader } from "~/components/modal-header";
import { asyncLogEvent, events } from "~/utils/analytics";
import {
  SettingsAvatar,
  SettingsListItem,
  SettingsListItemContainer,
  SettingsListItemDivider,
  SettingsScreen,
} from "~/components/settings";
import { useAlert } from "~/components/alert";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { preLogout } from "~/state/user/actions";
import { getEnvironment, getIsAdmin } from "~/state/user/selectors";
import { formatMessage } from "~/utils/translation";
import * as routes from "~/constants/routes";
import { makeExternalLinks } from "~/utils/external-links";
import { getRemoteConfigValue } from "~/state/config";
import { toUpperCase } from "~/utils/strings";

import {
  DeleteAccountButton,
  DeleteAccountButtonContainer,
  DeleteAccountButtonIcon,
  DeleteAccountButtonText,
  WebContainer,
  NavigationContainer,
  ContentContainer,
} from "./styles";
import { messages } from "./intl";
import { AppSettings } from "../app-settings";
import { Profile } from "../profile";
import { DevTools } from "../dev-tools";
import { HelpAndFeedback } from "../help-and-feedback";
import { About } from "../about";
import { colors } from "~/styles/theme";
import { HeaderBar } from "~/components/header-bar";
import { PayItForward } from "../pay-it-forward";

type SettingsProps = NativeStackScreenProps<SettingsStackParamList, "settings">;

const componentMap = new Map([
  [routes.appSettings, AppSettings],
  [routes.profile, Profile],
  [routes.about, About],
  [routes.helpAndFeedback, HelpAndFeedback],
  [routes.devTools, DevTools],
  [routes.payItForward, PayItForward],
]);

const tabs = [
  routes.appSettings,
  routes.profile,
  routes.about,
  routes.devTools,
  routes.payItForward,
];

export const Settings = () => {
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = React.useState(false);
  const navigation = useNavigation<SettingsProps["navigation"]>();
  const route = useRoute();
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(getIsAdmin);
  const env = useAppSelector(getEnvironment);
  const isGivingEnabled = useAppSelector((state) =>
    getRemoteConfigValue(state, `givingEnabled${toUpperCase(env)}`)
  );

  const { tab } = route?.params || {};

  const defaultRoute = tabs.includes(tab) ? tab : routes.profile;

  const [screen, setScreen] = React.useState(defaultRoute);

  const updateScreen = React.useCallback(
    (webScreen: string) => {
      setScreen(webScreen);
      navigation?.setParams({ tab: webScreen, step: undefined });
    },
    [navigation]
  );

  const { showAlert } = useAlert();

  const onAppSettingsPress = React.useCallback(
    () => updateScreen(routes.appSettings),
    [updateScreen]
  );

  const onEditProfilePress = React.useCallback(
    () => updateScreen(routes.profile),
    [updateScreen]
  );

  const onAboutPress = React.useCallback(
    () => updateScreen(routes.about),
    [updateScreen]
  );

  const onHelpAndFeedbackPress = React.useCallback(
    () => setScreen(routes.helpAndFeedback),
    [setScreen]
  );

  const onGivePress = React.useCallback(async () => {
    if (isGivingEnabled) {
      updateScreen(routes.payItForward);
      return;
    }
    await openBrowserAsync(makeExternalLinks().GIVE, {
      presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
    });
  }, [navigation, isGivingEnabled]);

  const onDevToolsPress = React.useCallback(
    () => updateScreen(routes.devTools),
    [updateScreen]
  );

  const onLogoutPress = React.useCallback(() => {
    asyncLogEvent(events.LOGOUT);
    setIsLoggingOut(true);
    setTimeout(() => {
      dispatch(preLogout());
    }, 0);
  }, [dispatch]);

  const onDeleteAccountPress = React.useCallback(() => {
    if (isDeletingAccount) {
      return;
    }
    setIsDeletingAccount(true);

    showAlert(
      formatMessage(messages.deleteAccountConfirmation),
      formatMessage(messages.deleteAccountConfirmationDescription),
      [
        {
          text: formatMessage(messages.deleteAccountConfirmationCancel),
          onPress: () => setIsDeletingAccount(false),
          style: "cancel",
        },
        {
          text: formatMessage(messages.deleteAccountConfirmationDelete),
          onPress: () => {
            navigation.navigate(routes.deleteAccount);
            setIsDeletingAccount(false);
          },
          style: "destructive",
        },
      ]
    );
  }, [navigation, isDeletingAccount, showAlert]);

  const Component = componentMap.get(screen) || null;

  return (
    <SettingsScreen screenName="settings">
      <HeaderBar iconColor={colors.black} withBackButton />

      <WebContainer>
        <NavigationContainer>
          <SettingsListItemContainer>
            <SettingsListItem
              title={formatMessage(messages.editProfile)}
              leftIcon="account-outline"
              type="navigate"
              onPress={onEditProfilePress}
              isSelected={screen === routes.profile}
              isFirstItem
            />

            <SettingsListItemDivider />

            <SettingsListItem
              title={formatMessage(messages.appSettings)}
              leftIcon="cog-outline"
              type="navigate"
              onPress={onAppSettingsPress}
              isSelected={screen === routes.appSettings}
            />

            <SettingsListItemDivider />

            <SettingsListItem
              title={formatMessage(messages.about)}
              leftIcon="information-outline"
              type="navigate"
              onPress={onAboutPress}
              isSelected={screen === routes.about}
            />

            <SettingsListItemDivider />

            <SettingsListItem
              title={formatMessage(messages.helpAndFeedback)}
              leftIcon="help-circle-outline"
              type="navigate"
              onPress={onHelpAndFeedbackPress}
              isSelected={screen === routes.helpAndFeedback}
            />

            <SettingsListItemDivider />

            <SettingsListItem
              title={formatMessage(messages.give)}
              leftIcon="hand-extended-outline"
              type="navigate"
              onPress={onGivePress}
              isSelected={false}
            />

            <SettingsListItemDivider />

            {isAdmin ? (
              <>
                <SettingsListItem
                  title={formatMessage(messages.devTools)}
                  leftIcon="cog-outline"
                  type="navigate"
                  onPress={onDevToolsPress}
                  isSelected={screen === routes.devTools}
                />
                <SettingsListItemDivider />
              </>
            ) : null}

            <SettingsListItem
              title={formatMessage(messages.logout)}
              leftIcon="exit-to-app"
              type="navigate"
              isLastItem
              onPress={onLogoutPress}
              isLoading={isLoggingOut}
              isSelected={false}
            />
          </SettingsListItemContainer>
        </NavigationContainer>

        <ContentContainer>
          <Component />
        </ContentContainer>
      </WebContainer>

      <DeleteAccountButtonContainer>
        <DeleteAccountButton onPress={onDeleteAccountPress}>
          <DeleteAccountButtonIcon />

          <DeleteAccountButtonText>
            {formatMessage(messages.deleteAccount)}
          </DeleteAccountButtonText>
        </DeleteAccountButton>
      </DeleteAccountButtonContainer>
    </SettingsScreen>
  );
};
