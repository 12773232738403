export const messages = {
  giving: {
    id: "giving.my-giving",
    defaultMessage: "My Giving",
  },
  history: {
    id: "giving.history",
    defaultMessage: "Giving History",
  },
  manage: {
    id: "giving.manage",
    defaultMessage: "Manage Giving",
  },
  faq: {
    id: "giving.faq",
    defaultMessage: "Giving FAQs",
  },
  contact: {
    id: "giving.contact",
    defaultMessage: "Contact Us",
  },
};
