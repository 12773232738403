import React from "react";

import { Container, Title } from "../../styles";
import { AccountHeader } from "../../../header";
import { messages as generalMessages } from "../../intl";
import { Screens } from "../../../../types";

import { HistoryContent } from "./content";

interface Props {
  onBackPress: () => void;
  setScreen: (screen: Screens) => void;
  isLoading: boolean;
}

export const GivingHistory = React.memo<Props>(({ onBackPress, isLoading }) => {
  return (
    <Container>
      <AccountHeader onBackPress={onBackPress} />

      <Title>{generalMessages.history}</Title>

      <HistoryContent isLoading={isLoading} />
    </Container>
  );
});
