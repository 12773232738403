import * as React from "react";

import {
  Container,
  AccountContainer,
  Button,
  ButtonText,
  Title,
  ButtonWrapper,
} from "./styles";
import { messages } from "./intl";
import { useNavigation } from "@react-navigation/native";
import { Screens } from "../../types";

interface Props {
  step: Screens;
  setStep: (step: Screens) => void;
  hideBackButton?: boolean;
  hideAccountButton?: boolean;
}

interface AccountProps {
  onBackPress: () => void;
  onPress: () => void;
  onSave?: () => void;
  isSaveDisabled?: boolean;
  isLoading?: boolean;
}

export const Header = React.memo<Props>(
  ({ step, setStep, hideBackButton, hideAccountButton }) => {
    const navigation = useNavigation();

    const onBackPress = () => {
      if (hideBackButton) {
        return;
      }
      if (!step || step === Screens.AmountSelection) {
        navigation.goBack();
        return;
      }
      setStep(Screens.AmountSelection);
    };

    const goToAccount = React.useCallback(() => {
      setStep(Screens.Intro);
    }, [setStep]);

    const backMessage = !step ? messages.cancel : messages.back;

    return (
      <Container>
        <Button onPress={onBackPress}>
          {!hideBackButton ? (
            <ButtonText isUppercase>{backMessage}</ButtonText>
          ) : null}
        </Button>
        {!hideAccountButton ? (
          <Button onPress={goToAccount}>
            <ButtonText isUppercase isActive isLast>
              {messages.account}
            </ButtonText>
          </Button>
        ) : null}
      </Container>
    );
  }
);

export const AccountHeader = React.memo<AccountProps>(
  ({ onBackPress, onSave, isSaveDisabled, isLoading, onPress }) => {
    const isSave = typeof onSave === "function";
    return (
      <AccountContainer>
        <Button onPress={onBackPress}>
          <ButtonText>{messages.back}</ButtonText>
        </Button>
        <Title>{messages.account}</Title>

        <ButtonWrapper>
          {!isSave ? (
            <Button onPress={onPress}>
              <ButtonText isLast>{messages.help}</ButtonText>
            </Button>
          ) : null}

          {isSave && !isSaveDisabled ? (
            <Button onPress={onSave}>
              <ButtonText isLast isActive isLoading={isLoading}>
                {messages.save}
              </ButtonText>
            </Button>
          ) : null}
        </ButtonWrapper>
      </AccountContainer>
    );
  }
);
