import Svg from "react-native-svg";
import styled from "styled-components/native";

import { HEADER_BAR_HEIGHT } from "~/constants";
import { isWeb } from "~/utils/platform";

export const HeaderContainer = styled.View`
  flex-direction: column;
`;

export const Background = styled(Svg)`
  position: absolute;
  width: 100%;
  height: ${isWeb ? 350 : 600}px;
  ${isWeb ? `top: ${HEADER_BAR_HEIGHT}px` : ""}
`;
