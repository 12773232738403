import * as React from "react";
import i18next from "i18next";
import { Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { useAppSelector } from "~/state/hooks";
import { getFeaturedContent } from "~/state/flamelink";
import { getLanguagesToDisplay } from "~/state/settings";

import {
  Container,
  Content,
  SectionTitle,
  ImageItem,
  Item,
  ItemButton,
  ButtonText,
  Text,
} from "./styles";
import { messages } from "./intl";

interface Props {}

export const FeaturedCarousel = React.memo<Props>(() => {
  const data = useAppSelector(getFeaturedContent);
  const languages = useAppSelector(getLanguagesToDisplay);

  const navigation = useNavigation<any>();

  const handleOnPress = React.useCallback(
    ({ plan, session }: { plan?: string; session?: string }) => {
      if (plan) {
        navigation.navigate(routes.plan, { planId: plan });
        return;
      }
      if (plan && session) {
        navigation.navigate(routes.session, {
          planId: plan,
          sessionId: session,
          volumeIndex: 0,
          sessionIndex: 0,
          dayIndex: 0,
        });
        return;
      }
    },
    [navigation]
  );

  const title = React.useMemo(() => {
    if (languages.length === 1 && languages[0] === "es") {
      const es = i18next.getFixedT("es");
      return es(messages.title?.id);
    } else {
      return messages.title;
    }
  }, [languages]);

  if (!data.length) {
    return;
  }

  return (
    <Container>
      <SectionTitle>{title}</SectionTitle>
      <Content showsHorizontalScrollIndicator={false} horizontal>
        {data.map(({ id, text, buttonText, type, uri = "", plan, session }) => {
          const onPress = () => handleOnPress({ plan, session });
          if (type === "text") {
            return (
              <Item key={id}>
                {text ? <Text>{text}</Text> : null}
                {buttonText ? (
                  <ItemButton onPress={onPress}>
                    <ButtonText>{buttonText}</ButtonText>
                  </ItemButton>
                ) : null}
              </Item>
            );
          }
          if (type === "image" && uri) {
            return (
              <Pressable onPress={onPress} key={id}>
                <ImageItem source={{ uri }} />
              </Pressable>
            );
          }
          return null;
        })}
      </Content>
    </Container>
  );
});
