export const messages = {
  editProfile: {
    id: "settings.edit-profile",
    defaultMessage: "Edit Profile",
  },
  appSettings: {
    id: "settings.app-settings",
    defaultMessage: "App Settings",
  },
  about: {
    id: "settings.about",
    defaultMessage: "About",
  },
  helpAndFeedback: {
    id: "settings.help-and-feedback",
    defaultMessage: "Help & Support",
  },
  give: {
    id: "settings.give",
    defaultMessage: "Give",
  },
  appFeedback: {
    id: "settings.app-feedback",
    defaultMessage: "App Feedback",
  },
  downloads: {
    id: "settings.downloads",
    defaultMessage: "Downloads",
  },
  devTools: {
    id: "settings.dev-tools",
    defaultMessage: "Developer tools",
  },
  logout: {
    id: "settings.logout",
    defaultMessage: "Sign Out",
  },
  deleteAccount: {
    id: "settings.delete-account",
    defaultMessage: "Delete Account",
  },
  deleteAccountConfirmation: {
    id: "settings.delete-account-confirmation",
    defaultMessage: "Are you sure you want to permanently delete your account?",
  },
  deleteAccountConfirmationDescription: {
    id: "settings.delete-account-confirmation-description",
    defaultMessage:
      "By deleting your account, you will lose all progress, notes, messages, and any data associated with this account.",
  },
  deleteAccountConfirmationCancel: {
    id: "settings.delete-account-confirmation-cancel",
    defaultMessage: "Cancel",
  },
  deleteAccountConfirmationDelete: {
    id: "settings.delete-account-confirmation-delete",
    defaultMessage: "Delete",
  },
};
