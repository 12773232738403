export const messages = {
  downloadAll: {
    id: "session-downloads.all",
    defaultMessage: "Download All",
  },
  downloadVolume: {
    id: "session-downloads.volume",
    defaultMessage: "Download Volume {{volumeNumber}}",
  },
  volumeOffline: {
    id: "session-downloads.volume-offline",
    defaultMessage: "View Volume Offline",
  },
  fileName: {
    id: "session-downloads.file-name",
    defaultMessage: "{{planName}}_Volume{{volumeNumber}}",
  },
};
