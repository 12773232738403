import styled from "styled-components/native";
import { StyleSheet } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";

import { spacers, colors } from "~/styles/theme";
import { Text4, Text6 } from "~/components/text";

export const Title = styled(Text6)<{ hasMargin?: boolean }>`
  margin-top: ${spacers.ss2};
  margin-bottom: ${({ hasMargin }) => (hasMargin ? spacers.ss8 : spacers.ss0)};
  font-family: MontserratMedium;
  color: ${colors.gray800};
`;

export const Meta = styled(Text4)`
  margin-top: ${spacers.ss4};
  margin-bottom: ${spacers.ss7};
  color: ${colors.gray800};
`;

export const Content = styled.View`
  margin-top: ${spacers.ss4};
  align-items: center;
`;

export const ButtonContainer = styled.View`
  margin-bottom: ${spacers.ss4};
`;

export const ImageBox = styled.TouchableOpacity`
  width: 88px;
  height: 88px;
  border-radius: 44px;
  background-color: ${colors.gray200};
  padding-vertical: ${spacers.ss6};
  margin-bottom: ${spacers.ss8};
  margin-top: ${spacers.ss6};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-color: ${colors.gray400};
`;

export const ImagePreview = styled.Image`
  width: 88px;
  height: 88px;
`;

export const ImageIcon = styled(Icon).attrs({
  name: "image",
  size: 50,
  color: colors.gray300,
})``;

export const styles = StyleSheet.create({
  locationInput: {
    fontSize: 17,
    lineHeight: 23,
    color: colors.black,
    fontFamily: "SFProDisplay",
    paddingHorizontal: 12,
    paddingVertical: 20,
    width: "100%",
    borderBottomWidth: 0.5,
    borderBottomColor: colors.gray450,
  },
});

export const CenterBox = styled.View`
  justify-content: center;
  align-items: center;
  margin-vertical: ${spacers.ss6};
`;

export const PhotoBox = styled.View`
  position: relative;
`;

export const CameraBox = styled.TouchableOpacity`
  background-color: ${colors.gray200};
  width: 24px;
  height: 24px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 24px;
  right: 0px;
  border-color: ${colors.white};
  border-width: 2px;
`;
