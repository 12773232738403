import * as React from "react";
import { ActivityIndicator } from "react-native";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";

import { Container, Text } from "./styles";
import { useAppSelector } from "~/state/hooks";
import { colors } from "~/styles/theme";
import {
  FlamelinkFile,
  getPlanByVolumeId,
  getVolumeFileIds,
  loadFlamelinkFiles,
} from "~/state/flamelink";
import { downloadFilesAsZip } from "~/utils/zip";

import { messages } from "./intl";
import { formatMessage } from "~/utils/translation";
import { useDispatch } from "react-redux";

interface Props {
  volumeIndex: number;
  volumeId: string;
}

export const DownloadAll = ({ volumeIndex, volumeId }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const fileIds = useAppSelector((state) => getVolumeFileIds(state, volumeId));
  const plan = useAppSelector((state) => getPlanByVolumeId(state, volumeId));
  const dispatch = useDispatch();

  const planName = (plan?.title || "").replace(/\s/g, "_");
  const volumeNumber = volumeIndex + 1;

  const fileName = formatMessage(messages.fileName, {
    planName,
    volumeNumber,
  });

  const handlePress = React.useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    dispatch(
      loadFlamelinkFiles({
        fileIds,
        onSuccess: (data: FlamelinkFile[]) => {
          downloadFilesAsZip(data, fileName, () => setIsLoading(false));
        },
        onError: () => setIsLoading(false),
      })
    );
  }, [fileName, isLoading, dispatch, fileIds]);

  return (
    <Container onPress={handlePress}>
      <Icon name="download" color={colors.gray500} size={14} />
      <Text>{{ ...messages.downloadVolume, values: { volumeNumber } }}</Text>
      {isLoading ? (
        <ActivityIndicator size={12} color={colors.gray500} />
      ) : null}
    </Container>
  );
};
