import * as React from "react";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  getHasFaithlifeNotes,
  getSessionNotesCount,
} from "~/state/notes/selectors";
import { getBibleNotes } from "~/state/bible-notes";
import { getPersonalNotes } from "~/state/personal-notes/actions";
import { getSessionNotesMeta } from "~/state/session-notes/actions";
import { HeaderBar } from "~/components/header-bar";
import { Screen } from "~/components/screen";
import { colors } from "~/styles/theme";
import { getHasMigratedNotes } from "~/utils/first-notes";
import { useAlerts } from "~/state/alerts";
import { isWeb } from "~/utils/platform";
import { useTutorial } from "~/state/tutorial";
import { getTutorialStep } from "~/state/tutorial/selectors";

import { NotesContent } from "./components/content";

import { Container, TitleBox, Title } from "./styles";
import { messages } from "./intl";

export const Notes = () => {
  const sessionNotesCount = useAppSelector(getSessionNotesCount);
  const hasFaithlifeNotes = useAppSelector(getHasFaithlifeNotes);

  const [isLoadingSession, setIsLoadingSession] = React.useState(false);
  const [isLoadingBible, setIsLoadingBible] = React.useState(false);
  const [isLoadingPersonal, setIsLoadingPersonal] = React.useState(false);
  const [hasMigratedNotes, setHasMigratedNotes] = React.useState(false);

  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  const { showTutorial } = useTutorial();
  const tutorialStep = useAppSelector((state) =>
    getTutorialStep(state, "notes")
  );

  React.useEffect(() => {
    showTutorial(tutorialStep);
  }, [tutorialStep, showTutorial]);

  React.useEffect(() => {
    setIsLoadingSession(true);
    dispatch(
      getSessionNotesMeta({
        onSuccess: () => setIsLoadingSession(false),
        onError: () => setIsLoadingSession(false),
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    setIsLoadingBible(true);
    dispatch(
      getBibleNotes({
        onSuccess: () => setIsLoadingBible(false),
        onError: () => setIsLoadingBible(false),
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    setIsLoadingPersonal(true);
    dispatch(
      getPersonalNotes({
        onSuccess: () => setIsLoadingPersonal(false),
        onError: () => setIsLoadingPersonal(false),
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    getHasMigratedNotes().then(setHasMigratedNotes);
  }, []);

  React.useEffect(() => {
    if (sessionNotesCount && hasFaithlifeNotes && hasMigratedNotes) {
      pushAlert({ message: messages.migratedNotes, color: colors.emerald600 });
    }
  }, [sessionNotesCount, hasFaithlifeNotes, hasMigratedNotes, pushAlert]);

  return (
    <Screen
      screenName="notes"
      backgroundColor={colors.gray75}
      isView
      isListView
    >
      <HeaderBar iconColor={colors.black} withBackButton />

      <Container>
        {!isWeb ? (
          <TitleBox>
            <Title>{messages.title}</Title>
          </TitleBox>
        ) : null}

        <NotesContent
          isLoadingSession={isLoadingSession}
          isLoadingBible={isLoadingBible}
          isLoadingPersonal={isLoadingPersonal}
        />
      </Container>
    </Screen>
  );
};
