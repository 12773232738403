import * as React from "react";

import { InputBox } from "~/components/auth-screen/styles";
import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { GoogleLocationInput } from "~/components/google-location-input";
import { LocationInput } from "~/components/location-input";
import { getCurrentLocale } from "~/utils/translation";
import ProfanityFilter from "~/utils/profanityFilter";
import { isWeb } from "~/utils/platform";

import { messages as genericMessages } from "../intl";
import { ChurchNameData } from "../types";
import {
  Container,
  Content,
  ButtonWrapper,
  ButtonBox,
  BottomWrapper,
} from "../styles";
import { Meta, Title } from "./styles";
import { messages } from "./intl";
import type { GooglePlaceData } from "react-native-google-places-autocomplete";
import Pager from "~/components/pager";

import { getValuesFromMap, useChurchNameData } from "./utils";

type Props = {
  onPress: (data: ChurchNameData) => void;
  onBackPress: () => void;
  churchName?: string;
  churchZip?: string;
  progressStep: (step: number) => void;
};

export const ChurchNameInputs = React.memo<Props>(
  ({
    churchName: defaultChurchName = "",
    onPress,
    progressStep,
    churchZip,
  }) => {
    const [churchName, setChurchName] = React.useState(defaultChurchName);
    const profanityFilter = new ProfanityFilter({
      language: getCurrentLocale(),
    });

    const { data: churchData, loading } = useChurchNameData(churchZip);

    const handleSubmit = React.useCallback(() => {
      if (churchName) {
        onPress({ churchName });
      }
    }, [onPress, churchName]);

    const handlePress = (data: GooglePlaceData) => {
      const [name = ""] = data?.description?.split(/\s*\-|,\s*/g);
      setChurchName(name);
    };

    const handleTextChange = (data: string) => {
      setChurchName(data);
    };

    const keyProps = isWeb ? { key: churchName } : {};

    const options = React.useMemo(
      () => getValuesFromMap(churchData, churchName),
      [churchData, churchName]
    );

    return (
      <>
        <Container>
          <Content scrollEnabled={false} keyboardShouldPersistTaps="always">
            <Title>{messages.churchTitle}</Title>
            <Meta>{messages.churchNameMeta}</Meta>
            <InputBox>
              {options.length || loading ? (
                <LocationInput
                  type="church"
                  label={messages.churchName}
                  onPress={handleTextChange}
                  onChangeText={handleTextChange}
                  value={churchName}
                  options={churchData}
                />
              ) : (
                <GoogleLocationInput
                  type="church"
                  label={messages.churchName}
                  onPress={handlePress}
                  onChangeText={handleTextChange}
                  hasBlur
                  onRenderResult={(row) =>
                    profanityFilter.replace(row?.description)
                  }
                  value={churchName}
                  isActive
                  {...keyProps}
                />
              )}
            </InputBox>
          </Content>
        </Container>

        <BottomWrapper>
          <Pager
            pages={3}
            currentPage={1}
            isValid={!!churchName}
            setPage={progressStep}
          />

          <ButtonBox>
            <Button
              text={genericMessages.buttonNext}
              onPress={handleSubmit}
              type={ButtonTypes.Primary}
              isDisabled={!churchName}
              isUppercase
            />
          </ButtonBox>
        </BottomWrapper>
      </>
    );
  }
);
