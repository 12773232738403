import * as routes from "~/constants/routes";
import { messages } from "~/components/tab-bar/intl";
import { IconTypes } from "~/components/icon";

export const menuItems = [
  {
    routeName: routes.home,
    icon: IconTypes.Home,
    label: messages.home,
  },
  {
    routeName: routes.library,
    icon: IconTypes.Library,
    label: messages.library,
  },
  {
    routeName: routes.bible,
    icon: IconTypes.Bible,
    label: messages.bible,
  },
  {
    routeName: routes.notes,
    icon: IconTypes.Notes,
    label: messages.notes,
  },
  {
    routeName: routes.groups,
    icon: IconTypes.Groups,
    label: messages.groups,
  },
];
