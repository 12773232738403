import decodeUriComponent from "decode-uri-component";

export const isValidEmail = (string: string) =>
  !!string.trim().match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

export const isValidPassword = (string: string) =>
  !!string.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).{8,}$/);

export const toUpperCase = (string: string) => {
  if (string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return string;
  }
};

export const isValidString = (string: string, minCharacters: number = 0) =>
  string.length >= minCharacters;

export const getVerseArray = (verseNumbers: string[]): string[] => {
  const [fromVerse, toVerse] = verseNumbers;

  if (!fromVerse) {
    return [];
  }
  if (!toVerse) {
    return [fromVerse];
  }

  const from = Number(fromVerse) || 0;
  const to = Number(toVerse) || 0;
  const verseCount = Math.abs(to - from + 1);

  return Array(verseCount)
    .fill(0)
    .map((_, i) => `${from + i}`);
};

export const bookNameMapping: { [key: string]: string[] } = {
  GEN: ["Génesis", "Genesis"],
  EXO: ["Éxodo", "Exodus"],
  LEV: ["Levítico", "Leviticus"],
  NUM: ["Números", "Numbers"],
  DEU: ["Deuteronomio", "Deuteronomy"],
  JOS: ["Josué", "Joshua"],
  JDG: ["Jueces", "Judges"],
  RUT: ["Rut", "Ruth"],
  "1SA": ["1 Samuel", "1 Samuel"],
  "2SA": ["2 Samuel", "2 Samuel"],
  "1KI": ["1 Reyes", "1 Kings"],
  "2KI": ["2 Reyes", "2 Kings"],
  "1CH": ["1 Crónicas", "1 Chronicles"],
  "2CH": ["2 Crónicas", "2 Chronicles"],
  EZR: ["Esdras", "Ezra"],
  NEH: ["Nehemías", "Nehemiah"],
  EST: ["Ester", "Esther"],
  JOB: ["Job", "Job"],
  PSA: ["Salmo", "Psalms", "Psalm"],
  PRO: ["Proverbios", "Proverbs"],
  ECC: ["Eclesiastés", "Ecclesiastes"],
  SNG: ["Cantares", "Songs of Solomon"],
  ISA: ["Isaías", "Isaiah"],
  JER: ["Jeremías", "Jeremiah"],
  LAM: ["Lamentaciones", "Lamentations"],
  EZK: ["Ezequiel", "Ezekiel"],
  DAN: ["Daniel", "Daniel"],
  HOS: ["Oseas", "Hosea"],
  JOL: ["Joel", "Joel"],
  AMO: ["Amós", "Amos"],
  OBA: ["Abdías", "Obadiah"],
  JON: ["Jonás", "Jonah"],
  MIC: ["Miqueas", "Micah"],
  NAM: ["Nahúm", "Nahum"],
  HAB: ["Habacuc", "Habakkuk"],
  ZEP: ["Sofonías", "Zephaniah"],
  HAG: ["Hageo", "Haggai"],
  ZEC: ["Zacarías", "Zechariah"],
  MAL: ["Malaquías", "Malachi"],
  MAT: ["Mateo", "Matthew"],
  MRK: ["Marcos", "Mark"],
  LUK: ["Lucas", "Luke"],
  JHN: ["Juan", "John"],
  ACT: ["Hechos", "Acts"],
  ROM: ["Romanos", "Romans"],
  "1CO": ["1 Corintios", "1 Corinthians"],
  "2CO": ["2 Corintios", "2 Corinthians"],
  GAL: ["Gálatas", "Galatians"],
  EPH: ["Efesios", "Ephesians"],
  PHP: ["Filipenses", "Philippians"],
  COL: ["Colosenses", "Colossians"],
  "1TH": ["1 Tesalonicenses", "1 Thessalonians"],
  "2TH": ["2 Tesalonicenses", "2 Thessalonians"],
  "1TI": ["1 Timoteo", "1 Timothy"],
  "2TI": ["2 Timoteo", "2 Timothy"],
  TIT: ["Tito", "Titus"],
  PHM: ["Filemón", "Philemon"],
  HEB: ["Hebreos", "Hebrews"],
  JAS: ["Santiago", "James"],
  "1PE": ["1 Pedro", "1 Peter"],
  "2PE": ["2 Pedro", "2 Peter"],
  "1JN": ["1 Juan", "1 John"],
  "2JN": ["2 Juan", "2 John"],
  "3JN": ["3 Juan", "3 John"],
  JUD: ["Judas", "Jude"],
  REV: ["Apocalipsis", "Revelation"],
};

const getBookId = (name: string) => {
  const keys = Object.keys(bookNameMapping);
  return keys.find((key: string) => {
    const names = bookNameMapping[key];
    return names.includes(name?.trim());
  });
};

export const getScriptureData = (rawScripture: string = "") => {
  const scripture = decodeUriComponent(rawScripture);
  const chunks = scripture.split(":");
  const bookChapterChunk = chunks[0];
  const verseChunks = chunks[1]?.split(",") || [];

  const bcChunks = bookChapterChunk.split(" ");
  const chapter = Number(bcChunks.pop()) || 0;
  const bookName = bcChunks.join(" ");

  const verses = verseChunks
    .map((chunk = "") => {
      const verseNumbers = chunk?.match(/\d+/g) || [];
      return getVerseArray(verseNumbers);
    })
    .flat();

  const bookId = getBookId(bookName);

  return {
    bookName,
    bookId,
    chapter,
    verses,
    scriptureText: bookId ? scripture : "",
  };
};

export const stripMarkdown = (text: string) =>
  text
    .replace(/\[(.*?)\]\(.*?\)/g, "$1") // Remove links but keep the link text
    .replace(/\*\*(.*?)\*\*/g, "$1") // Remove bold **
    .replace(/\*(.*?)\*/g, "$1") // Remove italic *
    .replace(/_(.*?)_/g, "$1") // Remove underline _
    .replace(/~~(.*?)~~/g, "$1") // Remove strikethrough ~~
    .replace(/`(.*?)`/g, "$1") // Remove inline code `
    .replace(/```[^```]*```/g, "") // Remove code blocks ```
    .replace(/#+\s*(.*)/g, "$1") // Remove headings
    .replace(/!\[.*?\]\(.*?\)/g, "") // Remove images
    .replace(/>\s*(.*)/g, "$1") // Remove blockquotes
    .replace(/^\s+|\s+$/g, ""); // Trim leading/trailing whitespace
