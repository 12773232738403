import React from "react";

import { useAlerts } from "~/state/alerts";
import { formatMessage } from "~/utils/translation";

import { Alert } from "./styles";

export const Alerts = React.memo(() => {
  const { alerts, removeAlert } = useAlerts();

  return (
    <>
      {alerts.map(({ id, message, color, isSilent }) => {
        const remove = () => {
          removeAlert(id);
        };

        const text =
          typeof message === "string"
            ? message
            : formatMessage(message as MessageDescriptorValues);

        if (isSilent) return null;

        return (
          <Alert visible onDismiss={remove} key={id} color={color}>
            {text}
          </Alert>
        );
      })}
    </>
  );
});
