import { createSelector } from "@reduxjs/toolkit";
import Constants from "expo-constants";

import { RootState } from "../store";
import { PaymentMethod, Subscription, Transaction } from "./types";
import { getEnvironment } from "../user/selectors";
import { supportedCurrencyMap } from "./utils";

const getState = (state: RootState) => state.payments;

export const getSubscription: (state: RootState) => Subscription | null =
  createSelector(getState, (state) => state?.subscription);

export const getPaymentMethods: (state: RootState) => PaymentMethod[] =
  createSelector(getState, (state) => state?.paymentMethods);

export const getPaymentIntents: (state: RootState) => Transaction[] =
  createSelector(getState, (state) => state?.paymentIntents || []);

export const getSubscriptionPaymentIntent: (
  state: RootState
) => Transaction | undefined = createSelector(
  getPaymentIntents,
  (paymentIntents) =>
    paymentIntents.find((intent) => intent.setup_future_usage === "off_session")
);

export const getSubscriptionPaymentIntentId: (state: RootState) => string =
  createSelector(
    getSubscriptionPaymentIntent,
    (paymentIntent) => paymentIntent?.id || ""
  );

export const getSubscriptionMethodId: (state: RootState) => string =
  createSelector(
    getSubscription,
    (subscription) => subscription?.paymentMethodId || ""
  );

export const getPublishableKey: (state: RootState) => string = createSelector(
  getEnvironment,
  (env) => {
    const config = Constants?.expoConfig?.extra;
    return env === "stg"
      ? config?.publishableKeyStg
      : config?.publishableKeyPrd;
  }
);

export const getIsTestMode: (state: RootState) => boolean = createSelector(
  getPublishableKey,
  (key) => key.includes("test")
);

export const getPaymentCurrency: (state: RootState) => string = createSelector(
  getState,
  (state) => state.currency
);

export const getPaymentCurrencyLabel: (state: RootState) => string =
  createSelector(
    getPaymentCurrency,
    (currency) => supportedCurrencyMap.get(currency) || ""
  );
