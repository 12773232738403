import * as React from "react";
import { Modal } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  getShouldShowNotification,
  getCurrentInAppNotification,
  dismissNotification,
} from "~/state/in-app-notifications";
import { Button } from "~/components/button";

import {
  Container,
  Content,
  ImageContent,
  TextContent,
  Title,
  Description,
  Image,
  ButtonContent,
  CloseButton,
} from "./styles";
import { Icon, IconTypes } from "../icon";
import { colors } from "~/styles/theme";

const INTERVAL = 1000;

export const InAppNotification = React.memo(() => {
  const shouldShowNotification = useAppSelector(getShouldShowNotification);
  const data = useAppSelector(getCurrentInAppNotification);
  const navigation = useNavigation();

  const dispatch = useAppDispatch();

  const [isVisible, setIsVisible] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState("");

  const onDismiss = React.useCallback(
    (isOpen?: boolean) => {
      setIsVisible(false);
      dispatch(dismissNotification({ isOpen }));
    },
    [dispatch]
  );

  const onPress = React.useCallback(() => {
    onDismiss(true);
    if (data?.navigateTo) {
      // We need to wait until the Modal animation finishes before we potentially
      // navigate to another screen of modal type, otherwise they would block one another
      setTimeout(() => {
        // @ts-ignore
        navigation.navigate(data?.navigateTo, data?.options || {});
      }, 600);
    }
  }, [data?.navigateTo, navigation, onDismiss]);

  React.useEffect(() => {
    let counter = 0;
    if (!data || data?.route !== currentRoute) {
      return;
    }

    if (data?.timeoutInSeconds) {
      const timeoutInterval = setInterval(() => {
        if (counter / 1000 > Number(data.timeoutInSeconds)) {
          setIsVisible(true);
          clearInterval(timeoutInterval);
        }
        counter = counter + INTERVAL;
      }, INTERVAL);
    } else {
      setIsVisible(true);
    }
  }, [data, currentRoute]);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("state", () => {
      const route = navigation?.getCurrentRoute();
      setCurrentRoute(route?.name);
    });

    return unsubscribe;
  }, [navigation]);

  if (!data || !shouldShowNotification) {
    return null;
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={() => {
        setIsVisible(!isVisible);
        onDismiss();
      }}
    >
      <Container>
        <CloseButton onPress={() => onDismiss()}>
          <Icon type={IconTypes.Close} color={colors.black} size={10} />
        </CloseButton>
        <Content>
          {data?.imageUrl ? (
            <ImageContent>
              <Image source={{ uri: data.imageUrl }} resizeMode="cover" />
            </ImageContent>
          ) : null}

          <TextContent>
            {data.title ? <Title>{data.title}</Title> : null}
            {data.description ? (
              <Description>{data.description}</Description>
            ) : null}
          </TextContent>

          {data?.buttonText ? (
            <ButtonContent>
              <Button text={data.buttonText} onPress={onPress} />
            </ButtonContent>
          ) : null}
        </Content>
      </Container>
    </Modal>
  );
});
