export const messages = {
  buttonPhoto: {
    id: "image-modal.button-photo",
    defaultMessage: "Take a picture",
  },
  buttonLibrary: {
    id: "image-modal.button-library",
    defaultMessage: "Upload from library",
  },
  buttonCancel: {
    id: "image-modal.button-cancel",
    defaultMessage: "Cancel",
  },
  buttonUpload: {
    id: "image-modal.button-upload",
    defaultMessage: "Upload",
  },
  buttonReplace: {
    id: "image-modal.button-replace",
    defaultMessage: "Replace",
  },
  selectAll: {
    id: "create-profile.select-all",
    defaultMessage: "Select all that apply",
  },
  // Basic
  basicTitle: {
    id: "create-profile.basic.title",
    defaultMessage:
      "Personalize your profile by adding a picture and your name.",
  },
  firstName: {
    id: "create-profile.first-name",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "create-profile.last-name",
    defaultMessage: "Last Name",
  },
  // Photo
  photoTitle: {
    id: "create-profile.photo.title",
    defaultMessage: "Select a profile photo",
  },
  emailVerificationTitle: {
    id: "create-profile.email-verification-title",
    defaultMessage: "Confirm your email address",
  },
  emailVerificationSubtitle: {
    id: "create-profile.email-verification-subtitle",
    defaultMessage: "We've sent a verification link to your email\n{{email}}",
  },
  emailVerificationDisclaimer: {
    id: "create-profile.email-verification-disclaimer",
    defaultMessage:
      "Check your spam folder in case the email was incorrectly identified",
  },
  emailVerificationResend: {
    id: "create-profile.email-verification-resend",
    defaultMessage: "Resend email",
  },
  emailVerificationSent: {
    id: "create-profile.email-verification-sent",
    defaultMessage: "Email link resent!",
  }, // Church
  churchTitle: {
    id: "create-profile.church.title",
    defaultMessage: "Discipleship happens when we do life together!",
  },
  churchMeta: {
    id: "create-profile.church.meta",
    defaultMessage: "Where is your church located?",
  },
  churchNameMeta: {
    id: "create-profile.church-name.meta",
    defaultMessage: "Add your church below",
  },
  churchCountry: {
    id: "create-profile.church.country",
    defaultMessage: "Country",
  },
  churchCity: {
    id: "create-profile.church.city",
    defaultMessage: "City",
  },
  churchState: {
    id: "create-profile.church.state",
    defaultMessage: "State/Province",
  },
  churchZip: {
    id: "create-profile.church.zip",
    defaultMessage: "Postal code",
  },
  // Church name
  churchNameTitle: {
    id: "create-profile.church-name.title",
    defaultMessage: "What is the name of your church?",
  },
  churchName: {
    id: "create-profile.church-name.label",
    defaultMessage: "Church name",
  },
  // Attendance
  attendanceTitle: {
    id: "create-profile.attendance.title",
    defaultMessage: "How frequently do you attend church?",
  },
  weeklyBasis: {
    id: "create-profile.attendance.weekly-basis",
    defaultMessage: "Weekly",
  },
  monthlyBasis: {
    id: "create-profile.attendance.monthly-basis",
    defaultMessage: "A few times each month",
  },
  yearlyBasis: {
    id: "create-profile.attendance.yearly-basis",
    defaultMessage: "A few times each year",
  },
  notRegularly: {
    id: "create-profile.attendance.not-regularly",
    defaultMessage: "I don't regularly attend church",
  },
  // Roles
  rolesTitle: {
    id: "create-profile.roles.title",
    defaultMessage: "What's your role in the church?",
  },
  leadPastor: {
    id: "create-profile.roles.lead-pastor",
    defaultMessage: "Lead Pastor",
  },
  childrensPastor: {
    id: "create-profile.roles.childrens-pastor",
    defaultMessage: "Kids Ministry Leader",
  },
  youthPastor: {
    id: "create-profile.roles.youth-pastor",
    defaultMessage: "Youth Ministry Leader",
  },
  smallGroupLeader: {
    id: "create-profile.roles.small-group-leader",
    defaultMessage: "Small Group Leader",
  },
  associatePastor: {
    id: "create-profile.roles.associate-pastor",
    defaultMessage: "Associate Pastor",
  },
  attendee: {
    id: "create-profile.roles.attendee",
    defaultMessage: "Attendee",
  },
  // Usage
  usageTitle: {
    id: "create-profile.usage.title",
    defaultMessage: "How do you plan on using this resource?",
  },
  smallGroups: {
    id: "create-profile.usage.small-groups",
    defaultMessage: "Small groups",
  },
  personalDevos: {
    id: "create-profile.usage.personal-devos",
    defaultMessage: "Personal devotions",
  },
  familyDevos: {
    id: "create-profile.usage.family-devos",
    defaultMessage: "Family devotions",
  },
  sundaySchool: {
    id: "create-profile.usage.sunday-school",
    defaultMessage: "Sunday school",
  },
  kidsMinistry: {
    id: "create-profile.usage.kids-ministry",
    defaultMessage: "Kids ministry",
  },
  youthMinistry: {
    id: "create-profile.usage.youth-ministry",
    defaultMessage: "Youth ministry",
  },
  other: {
    id: "create-profile.usage.other",
    defaultMessage: "Other",
  },
  // Church usage
  churchUsageTitle: {
    id: "create-profile.church-usage.title",
    defaultMessage:
      "Is your church using the resources from Bible Engagement Project?",
  },
  yes: {
    id: "create-profile.church-usage.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "create-profile.church-usage.no",
    defaultMessage: "No",
  },
  dontKnow: {
    id: "create-profile.church-usage.dont-know",
    defaultMessage: "I don't know",
  },
};
