import * as React from "react";

import { formatMessage } from "~/utils/translation";
import { useModal } from "~/state/modal/hook.web";
import { useAppSelector } from "~/state/hooks";
import { getPaymentCurrencyLabel } from "~/state/payments";
import { Currency, TextBox } from "~/screens/pay-it-forward/styles";

import {
  Title,
  Container,
  InputBox,
  TextInput,
  Button,
  ButtonText,
} from "./styles.web";
import { messages } from "./intl";

interface Props {
  value: string;
  hideModal: () => void;
  setValue: (value: string) => void;
}

export const CustomAmountComponent = React.memo<Props>(
  ({ hideModal, value, setValue }) => {
    const [localValue, setLocalValue] = React.useState(value);

    const currencyLabel = useAppSelector(getPaymentCurrencyLabel);
    const placeholder = formatMessage(messages.enter);

    const onPress = () => {
      setValue(localValue);
      hideModal();
    };

    const onChangePress = (text: string) => {
      if (!Number.isNaN(Number(text))) {
        setLocalValue(text);
      }
    };

    const isDisabled = !localValue;

    return (
      <Container>
        <Title>{messages.title}</Title>
        <InputBox>
          <TextBox>
            <Currency>{currencyLabel}</Currency>
            <TextInput
              placeholder={placeholder}
              value={localValue}
              onChangeText={onChangePress}
              keyboardType="numeric"
              // @ts-ignore
              style={{ outlineStyle: "none" }}
              autoFocus
            />
          </TextBox>
        </InputBox>
        <Button isDisabled={isDisabled} onPress={onPress}>
          <ButtonText>{messages.done}</ButtonText>
        </Button>
      </Container>
    );
  }
);

export const CustomAmount = React.memo<Props>(({ value, setValue }) => {
  const { showModal, hideModal } = useModal();

  React.useEffect(() => {
    showModal(
      <CustomAmountComponent
        hideModal={hideModal}
        value={value}
        setValue={setValue}
      />
    );
  }, [showModal, hideModal, value, setValue]);

  return null;
});
