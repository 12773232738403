import * as React from "react";
import { GestureResponderEvent } from "react-native";

import { useAppDispatch, useAppNetInfo, useAppSelector } from "~/state/hooks";
import {
  getGroupLeadersCount,
  getGroupMembersById,
  getIsGroupLeaderByGroupId,
} from "~/state/groups/selectors";
import { getUserId } from "~/state/user/selectors";
import { removeMember, changeRole } from "~/state/groups/actions";
import { formatMessage } from "~/utils/translation";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";
import { genericMessages } from "~/constants/intl";
import { useAlert } from "~/components/alert";
import { useActionSheet } from "~/components/action-sheet";
import { useTutorial } from "~/state/tutorial";
import { getTutorialStep } from "~/state/tutorial/selectors";

import { MemberItem } from "./components/item";
import { Container } from "./styles";
import { messages } from "./intl";

interface Props {
  groupId: string;
}

const getOptions = (isLeader: boolean, isGroupLeader: boolean) => {
  const leaderOptions = [
    isLeader ? messages.changeRoleLeader : messages.changeRoleMember,
  ];
  const basicOptions = [messages.remove, messages.cancel];

  const options = isGroupLeader
    ? [...leaderOptions, ...basicOptions]
    : basicOptions;

  return options.map((message) => formatMessage(message));
};

export const Members = React.memo<Props>(({ groupId }) => {
  const [loadingItem, setLoadingItem] = React.useState<string | null>(null);

  const members = useAppSelector((state) =>
    getGroupMembersById(state, groupId)
  );
  const isGroupLeader = useAppSelector((state) =>
    getIsGroupLeaderByGroupId(state, groupId)
  );
  const leadersCount = useAppSelector((state) =>
    getGroupLeadersCount(state, groupId)
  );
  const currentUserId = useAppSelector(getUserId);
  const membersCount = members.length;

  const { showActionSheetWithOptions } = useActionSheet();
  const { pushAlert } = useAlerts();
  const { showAlert } = useAlert();
  const { isConnected } = useAppNetInfo();
  const { showTutorial } = useTutorial();
  const dispatch = useAppDispatch();

  const onSuccess = React.useCallback(
    (message: TextType) => {
      setLoadingItem(null);
      pushAlert({
        message,
        color: colors.emerald600,
      });
    },
    [pushAlert]
  );

  const onError = React.useCallback(() => {
    setLoadingItem(null);
  }, []);

  const handleOffline = React.useCallback(() => {
    pushAlert({
      message: genericMessages.featureUnavailableOffline,
      color: colors.gray600,
    });
  }, [pushAlert]);

  const handleChangeRole = React.useCallback(
    (userId: string, isLeader: boolean) => {
      if (!isConnected) {
        handleOffline();

        return;
      }

      const isCurrentUser = userId === currentUserId;

      if (isCurrentUser && leadersCount <= 1) {
        showAlert(
          formatMessage(messages.changeRoleTitle),
          formatMessage(messages.changeRoleOnlyLeader)
        );
        return;
      }

      setLoadingItem(userId);
      dispatch(
        changeRole({
          userId,
          groupId,
          isLeader,
          onSuccess: () => onSuccess(messages.roleChanged),
          onError,
        })
      );
    },
    [
      isConnected,
      currentUserId,
      leadersCount,
      dispatch,
      groupId,
      onError,
      handleOffline,
      onSuccess,
      showAlert,
    ]
  );

  const handleRemove = React.useCallback(
    (userId: string, name: string) => {
      if (!isConnected) {
        handleOffline();

        return;
      }

      const isCurrentUser = userId === currentUserId;
      const removeText = isCurrentUser
        ? messages.removeYourself
        : { ...messages.removeMember, values: { name } };

      if (
        isCurrentUser &&
        isGroupLeader &&
        leadersCount <= 1 &&
        membersCount > 1
      ) {
        showAlert(
          formatMessage(messages.remove),
          formatMessage(messages.removeOnlyLeader)
        );
        return;
      }

      showAlert(formatMessage(messages.remove), formatMessage(removeText), [
        {
          text: formatMessage(messages.cancel),
          style: "cancel",
        },
        {
          text: formatMessage(messages.removeButton),
          onPress: () => {
            setLoadingItem(userId);
            dispatch(
              removeMember({
                userId,
                groupId,
                onSuccess: () => onSuccess(messages.memberRemoved),
                onError,
              })
            );
          },
        },
      ]);
    },
    [
      isConnected,
      currentUserId,
      isGroupLeader,
      leadersCount,
      membersCount,
      handleOffline,
      dispatch,
      groupId,
      onError,
      onSuccess,
      showAlert,
    ]
  );

  const handleActionButton = React.useCallback(
    (
      userId: string,
      isLeader: boolean,
      title: string,
      event: GestureResponderEvent
    ) => {
      const options = getOptions(isLeader, isGroupLeader);
      const cancelButtonIndex = options.length - 1;
      showActionSheetWithOptions(
        {
          title,
          options,
          cancelButtonIndex,
          destructiveButtonIndex: 1,
        },
        (selectedIndex?: number) => {
          switch (selectedIndex) {
            case 0: {
              if (isGroupLeader) {
                handleChangeRole(userId, isLeader);
              } else {
                handleRemove(userId, title);
              }
              return;
            }

            case 1: {
              if (isGroupLeader) {
                handleRemove(userId, title);
              }
              return;
            }
          }
        },
        // @ts-ignore
        event
      );
    },
    [showActionSheetWithOptions, handleRemove, handleChangeRole, isGroupLeader]
  );

  const tutorialStep = useAppSelector((state) =>
    getTutorialStep(state, "groups-members")
  );

  React.useEffect(() => {
    showTutorial(tutorialStep);
  }, [showTutorial, tutorialStep]);

  return (
    <Container>
      {members.map(({ id, firstName, lastName, imageUri, isLeader }, index) => {
        const name = [firstName, lastName].join(" ");
        const isLoading = loadingItem === id;
        const isLast = index === members.length - 1;
        const isCurrentUser = id === currentUserId;

        return (
          <MemberItem
            key={id}
            userId={id}
            name={name}
            isLast={isLast}
            imageUri={imageUri}
            onPress={handleActionButton}
            isLoading={isLoading}
            isLeader={!!isLeader}
            canEdit={isGroupLeader || isCurrentUser}
          />
        );
      })}
    </Container>
  );
});
