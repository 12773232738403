import * as React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Constants from "expo-constants";
import { formatMessage } from "~/utils/translation";

import "./styles.css";
import { webStyles } from "./styles";

type Props = {
  type: string;
  label: MessageDescriptor;
  language?: string;
  value?: string;
  onChangeText: (data: string) => void;
  hasBlur?: boolean;
};

export const GoogleLocationInput = React.memo<Props>(
  ({
    value: defaultInputValue,
    label: inputLabel,
    language = "en",
    type = "church",
    onChangeText,
    hasBlur,
  }) => {
    const [localValue, setLocalValue] = React.useState(defaultInputValue);
    const onChange = ({ label, value, rest }: any) => {
      onChangeText(value?.structured_formatting?.main_text || label);
    };

    const onInputChange = (value: string) => {
      setLocalValue(value);
    };

    const onBlur = React.useCallback(() => {
      if (hasBlur) {
        onChangeText(localValue || "");
      }
    }, [onChangeText, hasBlur, localValue]);

    const getOptionLabel = ({ value }: any): string =>
      value?.structured_formatting?.main_text;

    return (
      <GooglePlacesAutocomplete
        selectProps={{
          placeholder: formatMessage(inputLabel),
          onChange,
          unstyled: true,
          defaultInputValue,
          classNamePrefix: "gp",
          styles: webStyles,
          getOptionLabel,
          onBlur,
          onInputChange,
        }}
        minLengthAutocomplete={2}
        apiKey={Constants?.expoConfig?.extra?.googlePlacesApiKey}
        apiOptions={{ language }}
        autocompletionRequest={{
          types: [type],
        }}
      />
    );
  }
);
