export enum events {
  SCREEN_VIEW = "screen_view",
  BIBLE_NAVIGATION = "bible_navigation",
  BIBLE_MODAL_CHANGE = "bible_modal_change",
  SELECT_BIBLE_VERSION = "bible_select_version",
  BIBLE_DOWNLOAD = "bible_download",
  BIBLE_DELETE_DOWNLOADED = "bible_delete_downloaded",
  LIBRARY_SHOW_PLAN = "library_show_plan",
  LIBRARY_OPEN_SESSION = "library_open_session",
  LIBRARY_COMPLETED_DAY = "library_completed_day",
  PLAN_COMPLETED = "plan_completed",
  OPEN_QUESTION_SHEET = "library_open_question_sheet",
  SCRIPTURE_NAVIGATION = "library_scripture_navigation",
  SELECT_LIBRARY_FILTER = "library_select_filter",
  SELECT_NOTES_FILTER = "notes_notes_filter",
  NOTES_SHARE = "notes_share",
  BIBLE_NOTE_ADD = "notes_bible_note_add",
  BIBLE_NOTE_EDIT = "notes_bible_note_edit",
  BIBLE_NOTE_DELETE = "notes_bible_note_delete",
  SESSION_NOTE_ADD = "notes_session_note_add",
  SESSION_NOTE_EDIT = "notes_session_note_edit",
  SESSION_NOTE_DELETE = "notes_session_note_delete",
  PERSONAL_NOTE_ADD = "notes_personal_note_add",
  PERSONAL_NOTE_EDIT = "notes_personal_note_edit",
  PERSONAL_NOTE_DELETE = "notes_personal_note_delete",
  GROUP_JOINED = "groups_group_joined",
  GROUP_CREATED = "groups_group_created",
  GROUP_EDITED = "groups_group_edited",
  GROUP_DELETED = "groups_group_deleted",
  GROUP_CODE_REFRESH = "groups_code_refresh",
  MESSAGES_ADD = "messages_message_add",
  MESSAGES_DELETE = "messages_message_delete",
  LOGOUT = "settings_logout",
  DELETE_ACCOUNT = "settings_delete_account",
  USER_LOGIN_STANDARD = "user_login_standard",
  USER_LOGIN_GOOGLE = "user_login_google",
  USER_LOGIN_APPLE = "user_login_apple",
  APP_LANGUAGE_CHANGE = "settings_app_language_change",
  RESOURCE_LANGUAGE_CHANGE = "settings_resource_language_change",
  VIDEO_QUALITY = "settings_video_quality",
  FONT_SIZE = "settings_font_size",
  DOWNLOAD_FILE = "download_file",
  DOWNLOAD_SESSION = "download_session",
}
