import { StyleSheet } from "react-native";
import Icon from "@expo/vector-icons/Ionicons";
import styled from "styled-components/native";
import { IconSizes } from "~/components/icon";
import { colors } from "~/styles/theme";

export const markdownStyles: StyleSheet.NamedStyles<any> = {
  strong: {
    fontWeight: "normal",
    color: colors.amber700,
  },
  bullet_list: {
    color: colors.amber700,
  },
};

export const ListItemContainer = styled.View`
  flex-direction: row;
`;

export const ItemWrapper = styled.View`
  flex: 1;
`;

export const Microphone = styled(Icon).attrs({
  size: IconSizes.Medium,
  name: "ios-mic-off-outline",
  color: colors.amber700,
})<{ index: number }>`
  opacity: ${({ index }) => (index === 0 ? 1 : 0)};
`;
