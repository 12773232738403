import React from "react";
import uuid from "react-native-uuid";

import { AlertsContext } from "./context";
import { Alert, AlertInput } from "./types";

type Props = {
  children: React.ReactNode;
};

const ALERT_TIMEOUT = 2000;

export const AlertsProvider = ({ children }: Props) => {
  const [alerts, setAlerts] = React.useState<Alert[]>([]);

  const pushAlert = React.useCallback(
    (alert: AlertInput) => {
      const alertId = alert.id || `${uuid.v4()}`;

      if (alerts.find(({ id }) => id === alertId)) {
        return;
      }

      setAlerts((data) => {
        const alertAlreadyExists = !!data.find(({ id }) => id === alertId);

        return alertAlreadyExists
          ? data
          : data.concat([{ ...alert, id: alertId }]);
      });

      setTimeout(() => {
        setAlerts((data) => data.filter(({ id }) => id !== alertId));
      }, alert.duration || ALERT_TIMEOUT);
    },
    [setAlerts, alerts]
  );

  const removeAlert = React.useCallback((alertId: string) => {
    setAlerts((data) => data.filter(({ id }) => id !== alertId));
  }, []);

  const state = {
    alerts,
    pushAlert,
    removeAlert,
  };

  return (
    <AlertsContext.Provider value={state}>{children}</AlertsContext.Provider>
  );
};
