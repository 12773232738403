import React from "react";
import { Animated } from "react-native";
import * as NativeSplashScreen from "expo-splash-screen";

import { useAppDispatch } from "~/state/hooks";
import {
  appStartedLoading,
  splashScreenFinishedLoading,
} from "~/state/startup";
import { useLoadResources } from "~/state/startup/hooks";
import { isWeb } from "~/utils/platform";
import { IconTypes } from "~/components/icon";

import {
  SplashScreenContainer,
  BackgroundWrapper,
  AnimatedWrapper,
  BackgroundImage,
  StyledIcon,
  Container,
  LogoContent,
} from "./styles";

const FADE_OUT_DURATION = 400;
const HIDE_SPLASH_SCREEN_DELAY = 1200;

interface SplashScreenProps {
  children: JSX.Element;
}

const background = require("<assets>/resources/gradient.png");
const backgroundWeb = require("<assets>/resources/gradient-web.jpg");

export function SplashScreen({ children }: SplashScreenProps) {
  const [isAnimationCompleted, setIsAnimationCompleted] = React.useState(false);
  const dispatch = useAppDispatch();

  const opacity = React.useRef(new Animated.Value(1)).current;

  useLoadResources();

  React.useEffect(() => {
    dispatch(appStartedLoading());
  }, [dispatch]);

  React.useEffect(() => {
    setTimeout(() => {
      Animated.timing(opacity, {
        toValue: 0,
        duration: FADE_OUT_DURATION,
        useNativeDriver: true,
        delay: 0,
      }).start(() => {
        setIsAnimationCompleted(true);
        dispatch(splashScreenFinishedLoading());
      });
    }, HIDE_SPLASH_SCREEN_DELAY);
  }, [dispatch, opacity]);

  return (
    <SplashScreenContainer>
      {children}

      {!isAnimationCompleted ? (
        <AnimatedWrapper style={{ opacity }}>
          <SplashContent isAnimated>{null}</SplashContent>
        </AnimatedWrapper>
      ) : null}
    </SplashScreenContainer>
  );
}

interface ContentProps {
  isAnimated: boolean;
  children: React.ReactNode;
}

export const SplashContent = ({ children, isAnimated }: ContentProps) => {
  const initialPosition = isAnimated ? 100 : -50;
  const initialOpacity = isAnimated ? 0 : 1;
  const translateY = React.useRef(new Animated.Value(initialPosition)).current;
  const opacity = React.useRef(new Animated.Value(initialOpacity)).current;

  React.useEffect(() => {
    if (!isAnimated) {
      return;
    }
    Animated.timing(translateY, {
      toValue: -50,
      duration: FADE_OUT_DURATION,
      useNativeDriver: true,
    }).start();

    Animated.timing(opacity, {
      toValue: 1,
      duration: FADE_OUT_DURATION,
      useNativeDriver: true,
      delay: isWeb ? 500 : 100,
    }).start();
  }, [opacity, translateY, isAnimated]);

  const image = isWeb ? backgroundWeb : background;

  return (
    <Container>
      <BackgroundWrapper>
        <BackgroundImage
          source={image}
          contentFit="cover"
          cachePolicy="memory-disk"
          onLoadEnd={() => NativeSplashScreen.hideAsync()}
        />
      </BackgroundWrapper>

      <LogoContent style={{ transform: [{ translateY }] }}>
        <StyledIcon type={IconTypes.Logo} size={83} />
        <Animated.View style={{ opacity }}>
          <StyledIcon type={IconTypes.LogoText} size={190} />
        </Animated.View>
      </LogoContent>
      {children}
    </Container>
  );
};
