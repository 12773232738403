import styled, { css } from "styled-components/native";
import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

const linkStyle = css`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray500};
  margin-left: ${spacers.ss4};
`;

export const Container = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  min-width: 150px;
  margin-top: ${spacers.ss4};
`;

export const Text = styled(Text2)`
  color: ${colors.gray500};
  margin-right: ${spacers.ss4};
  margin-left: ${spacers.ss2};
  ${!isWeb ? linkStyle : null}
`;
