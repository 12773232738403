import * as React from "react";

import { InputBox } from "~/components/auth-screen/styles";
import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { Loader } from "~/components/loader";
import { LocationInput } from "~/components/location-input";
import { countryMap, statesMap } from "~/constants/countries";
import Pager from "~/components/pager";

import { messages as genericMessages } from "../intl";
import { ChurchData } from "../types";
import {
  Container,
  Content,
  ButtonWrapper,
  ButtonBox,
  BottomWrapper,
} from "../styles";
import { Meta, Title } from "./styles";
import { messages } from "./intl";
import { getLocation } from "./get-location";

import { useCityData, getKeyByValue, useZipCodeData } from "./utils";

const US = ["United States", "USA", "US"];

type Props = {
  onPress: (data: ChurchData) => void;
  churchCity?: string;
  churchState?: string;
  churchZip?: string;
  churchCountry?: string;
  progressStep: (step: number) => void;
};

export const ChurchInputs = React.memo<Props>(
  ({
    churchCity: defaultChurchCity = "",
    churchState: defaultChurchState = "",
    churchZip: defaultChurchZip = "",
    churchCountry: defaultCountry = "",
    onPress,
    progressStep,
  }) => {
    const [churchCountry, setChurchCountry] = React.useState(defaultCountry);
    const [churchCity, setChurchCity] = React.useState(defaultChurchCity);
    const [churchState, setChurchState] = React.useState(defaultChurchState);
    const [churchZip, setChurchZip] = React.useState(defaultChurchZip);
    const [visible, setVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const { data: cityData } = useCityData(
      getKeyByValue(countryMap, churchCountry),
      (churchCity[0] || "").toUpperCase()
    );

    const { data: zipCodeData } = useZipCodeData(churchZip);

    const isValid =
      churchCountry && US.includes(churchCountry)
        ? churchCountry && churchCity && churchState && churchZip
        : churchCountry && churchCity;

    const handleZipChange = (data: string) => {
      if (!data) {
        return;
      }
      const locationData = data.split(", ");

      if (locationData[0]) {
        setChurchZip(locationData[0]);
      }

      if (locationData[1]) {
        setChurchCity(locationData[1]);
      }
      if (locationData[2]) {
        setChurchState(locationData[2]);
        setVisible(US.includes(locationData[2]));
      }
      if (locationData[3]) {
        setChurchCountry(locationData[3]);
      }
    };

    const handleCountryChange = (data: string) => {
      const countryName = countryMap.get(data);
      setChurchCountry(countryName || data);
      setVisible(US.includes(data));
    };

    const handleStateChange = (data: string) => {
      if (data === churchState) {
        return;
      }

      setChurchState(data);
    };

    const handleCityChange = (data: string) => {
      if (data === churchCity) {
        return;
      }

      setChurchCity(data);
    };

    const handleSubmit = React.useCallback(() => {
      if (isValid) {
        onPress({ churchCountry, churchCity, churchState, churchZip });
      }
    }, [onPress, isValid, churchCountry, churchCity, churchState, churchZip]);

    React.useEffect(() => {
      (async () => {
        if (
          defaultCountry ||
          defaultChurchCity ||
          defaultChurchState ||
          defaultChurchZip
        ) {
          return;
        }
        setLoading(true);

        const location = await getLocation();

        if (!location) {
          setLoading(false);
        }

        if (location) {
          const { city, region, postalCode, country } = location;
          if (country) {
            handleCountryChange(country);
          }
          if (city) {
            setChurchCity(city);
          }
          if (region) {
            setChurchState(region);
          }
          if (postalCode) {
            setChurchZip(postalCode);
          }
        }

        setLoading(false);
      })();
    }, []);

    return (
      <>
        <Container>
          <Content scrollEnabled={false} keyboardShouldPersistTaps="always">
            <Title>{messages.churchTitle}</Title>
            <Meta>{messages.churchMeta}</Meta>
            {loading ? (
              <Loader />
            ) : (
              <InputBox>
                <LocationInput
                  key="churchZip"
                  type="postal_code"
                  label={messages.churchZip}
                  onPress={handleZipChange}
                  onChangeText={setChurchZip}
                  value={churchZip}
                  options={zipCodeData}
                />
                <LocationInput
                  key="churchCountry"
                  type="country"
                  label={messages.churchCountry}
                  onPress={handleCountryChange}
                  onChangeText={handleCountryChange}
                  value={churchCountry}
                  options={countryMap}
                />
                {visible ? (
                  <LocationInput
                    key="churchState"
                    type="state"
                    label={messages.churchState}
                    onPress={handleStateChange}
                    onChangeText={handleStateChange}
                    value={churchState}
                    options={statesMap}
                  />
                ) : null}
                <LocationInput
                  key="churchCity"
                  type="(cities)"
                  label={messages.churchCity}
                  onPress={handleCityChange}
                  onChangeText={handleCityChange}
                  value={churchCity}
                  options={cityData}
                />
              </InputBox>
            )}
          </Content>
        </Container>

        <BottomWrapper>
          <Pager
            pages={3}
            currentPage={0}
            isValid={!!isValid}
            setPage={progressStep}
          />

          <ButtonBox>
            <Button
              text={genericMessages.buttonNext}
              onPress={handleSubmit}
              type={ButtonTypes.Primary}
              isDisabled={!isValid}
              isUppercase
            />
          </ButtonBox>
        </BottomWrapper>
      </>
    );
  }
);
